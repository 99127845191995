import { Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormFieldRule, FormFieldRuleOperation } from '../../consts/formField';
import { ItemType } from '../../consts/rule';
import { DrawerSelectCategories } from '../DrawerSelectCategories';
import { DrawerSelectLabels } from '../DrawerSelectLabels';
import { FormulaOperation } from '../FormulaOperation/FormulaOperation';
import { ModalCustom } from '../Modal';
import {
  AddOperationTextLink,
  FormulaSectionCard,
  FormulaSectionContainer,
  SubTitleLabel,
  TitleLabel,
} from './FormulaSection.styles';

export const FormulaSection = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { watch, getValues, clearErrors, setValue } = useFormContext();
  const [isOpenModalConfirmDelete, setIsOpenModalConfirmDelete] = useState<boolean>(false);
  const [currentOperationIndex, setCurrentOperationIndex] = useState(-1);
  const [selectedItemType, setSelectedItemType] = useState<ItemType>(null);
  const isOpenDrawerSelectLabels = [ItemType.Poster, ItemType.Sku].includes(selectedItemType);
  const isOpenDrawerSelectCategories = [ItemType.Categories].includes(selectedItemType);
  const ruleType = watch(FormFieldRule.Type);
  const recognitionModel = watch(FormFieldRule.RecognitionModelId);
  const watchPreExpressionFields = watch([
    FormFieldRule.Name,
    FormFieldRule.Type,
    FormFieldRule.RecognitionModelId,
  ]);
  const isNotAllPreExpressionFilled = !watchPreExpressionFields.every((field) => Boolean(field));
  const {
    fields: operationFields,
    append: appendOperation,
    remove: removeOperation,
    update: updateOperation,
  } = useFieldArray({
    name: FormFieldRule.Operations,
  });
  const emptyOperator = {
    [FormFieldRuleOperation.Items]: [],
  };

  /* istanbul ignore next */
  function onCloseDrawerSelectLabels() {
    setSelectedItemType(null);
  }

  function handleAddOperation() {
    appendOperation(emptyOperator);
  }

  function handleDeleteOperation(operationIndex: number) {
    const ruleOperations = getValues(FormFieldRule.Operations);
    const currentOperation = ruleOperations[operationIndex];
    const isFieldTouched = Boolean(
      currentOperation[FormFieldRuleOperation.Method] ||
        currentOperation[FormFieldRuleOperation.Operator] ||
        currentOperation[FormFieldRuleOperation.Value] ||
        currentOperation[FormFieldRuleOperation.Items]?.length > 0
    );

    if (!isFieldTouched) {
      const hasMoreThanOneFieldFulfilled = operationFields.length > 1;
      if (hasMoreThanOneFieldFulfilled) {
        const isFirstOperation = operationIndex === 0;
        if (isFirstOperation) {
          setValue(
            `${FormFieldRule.Operations}[${operationIndex + 1}].${
              FormFieldRuleOperation.Condition
            }`,
            undefined
          );
        }
        removeOperation(operationIndex);
        clearErrors(FormFieldRule.Operations);
        deleteFirstOperationCompetition();
      }
    } else {
      setIsOpenModalConfirmDelete(true);
      setCurrentOperationIndex(operationIndex);
    }
  }

  /* istanbul ignore next */
  function handleOnDeleteOperationConfirmModal() {
    const hasMoreOperationsAfterCurrent = currentOperationIndex < operationFields.length - 1;
    if (hasMoreOperationsAfterCurrent) {
      setValue(
        `${FormFieldRule.Operations}[${currentOperationIndex + 1}].${
          FormFieldRuleOperation.Condition
        }`,
        undefined
      );
    }

    const isLastOperation = operationFields.length <= 1;
    if (isLastOperation) {
      appendOperation(emptyOperator);
    }

    removeOperation(currentOperationIndex);
    clearErrors(FormFieldRule.Operations);
    deleteFirstOperationCompetition();
    setIsOpenModalConfirmDelete(false);
  }

  /* istanbul ignore next */
  function deleteFirstOperationCompetition() {
    const ruleOperations = getValues(FormFieldRule.Operations);
    if (ruleOperations[0].itemType === ItemType.Competition) {
      updateOperation(0, emptyOperator);
      clearErrors(FormFieldRule.Operations);
    }
  }

  function handleSelectItem({
    selectedItem,
    operationIndex,
  }: {
    selectedItem: ItemType;
    operationIndex: number;
  }) {
    setSelectedItemType(selectedItem);
    setCurrentOperationIndex(operationIndex);
  }

  return (
    <FormulaSectionContainer>
      <TitleLabel>{formatMessage({ id: 'ruleCreation.form.formulaSection.title' })}</TitleLabel>
      <SubTitleLabel>
        {formatMessage({ id: 'ruleCreation.form.formulaSection.subtitle' })}
      </SubTitleLabel>
      <Tooltip
        data-testid="formula-tooltip"
        placement="top"
        arrow
        title={formatMessage({ id: 'ruleCreation.form.formulaSection.tooltip' })}
        disableHoverListener={!isNotAllPreExpressionFilled}
        disableFocusListener={true}
      >
        <FormulaSectionCard
          border={'small'}
          elevated={'minimal'}
          data-testid="formula-creation-card"
        >
          {operationFields.map((item, index) => (
            <FormulaOperation
              key={item.id}
              index={index}
              disabled={isNotAllPreExpressionFilled}
              onDeleteOperation={handleDeleteOperation}
              onSelectItem={handleSelectItem}
              isDrawerClosed={!isOpenDrawerSelectLabels && !isOpenDrawerSelectCategories}
            />
          ))}
          <AddOperationTextLink
            hasUnderline={false}
            data-testid="add-operation-link"
            onClick={handleAddOperation}
            disabled={isNotAllPreExpressionFilled}
          >
            + {formatMessage({ id: 'ruleCreation.form.formulaSection.addOperation' })}
          </AddOperationTextLink>
        </FormulaSectionCard>
      </Tooltip>

      <DrawerSelectLabels
        operationIndex={currentOperationIndex}
        isOpen={isOpenDrawerSelectLabels}
        itemType={selectedItemType}
        onClose={onCloseDrawerSelectLabels}
        selectedTrainedModelId={recognitionModel}
        ruleType={ruleType}
      />

      <DrawerSelectCategories
        operationIndex={currentOperationIndex}
        isOpen={isOpenDrawerSelectCategories}
        onClose={onCloseDrawerSelectLabels}
        selectedTrainedModelId={recognitionModel}
        ruleType={ruleType}
      />

      <ModalCustom
        buttonPrimaryLabel={formatMessage({
          id: 'ruleCreation.modal.deleteOperation.buttonPrimaryLabel',
        })}
        buttonSecondaryLabel={formatMessage({
          id: 'ruleCreation.modal.deleteOperation.buttonSecondaryLabel',
        })}
        description={formatMessage({ id: 'ruleCreation.modal.deleteOperation.description' })}
        title={formatMessage({ id: 'ruleCreation.modal.deleteOperation.title' })}
        handleConfirm={() => handleOnDeleteOperationConfirmModal()}
        isOpen={isOpenModalConfirmDelete}
        onClose={() => setIsOpenModalConfirmDelete(false)}
      />
    </FormulaSectionContainer>
  );
};
