export interface Rule {
  configId: string;
  ruleId: string;
  vendorId: string;
  vendorName: string;
  description: string;
  validationMode: ValidationMode;
  targetType: TargetType;
  createdAt: string;
  updatedAt: string;
  updatedBy: string;
}

export enum ItemType {
  Sku = 'SKU',
  Categories = 'CATEGORIES',
  Poster = 'POSTER',
  Competition = 'COMPETITION',
}

export enum RuleType {
  Sku = 'SKU',
  Advertisement = 'ADVERTISEMENT',
  Poster = 'Poster',
}

export enum ValidationMode {
  Prediction = 'PREDICTION',
  Collection = 'COLLECTION',
  AutoFinishedJob = 'AUTO_FINISHED_JOB',
}

export enum TargetType {
  Poster = 'Poster',
  Sku = 'SKU',
}
