import { LoadingBuzz } from '@hexa-ui/components';
import { DocumentTitle } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';

import { PageLoadingContainer } from './PageLoading.styles';

const PageLoading = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <>
      <DocumentTitle>{formatMessage({ id: 'title.pageLoading' })}</DocumentTitle>
      <PageLoadingContainer>
        <LoadingBuzz dataTestId="loading-buz-component" size="xlarge" />
      </PageLoadingContainer>
    </>
  );
};

export default PageLoading;
