import { Heading } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { Fade } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { HideBodyScrollbar, WindowShadow } from '../../../styles/GlobalStyles';
import { RootProps } from './Root.d';
import {
  ButtonCloseDrawer,
  ContainerTitle,
  DrawerContainer,
  DrawerFooterActions,
  DrawerHeader,
  DrawerHeaderActions,
  DrawerWrapper,
} from './Root.styles';

const Root = ({
  title = 'Drawer Component',
  isOpen,
  hideBackdrop = false,
  onCloseDrawer,
  className,
  children,
  footerActions,
  headerActions,
  shouldCloseOnBackdropClick = true,
  shouldUnmountOnClose = true,
  slideDurationMs = 1000,
  widthPx = 432,
  nested,
  ...rest
}: RootProps): JSX.Element => {
  const [isMounted, setIsMounted] = useState(false);
  const [isOpenInternal, setIsOpenInternal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      setIsOpenInternal(false);
      setTimeout(() => {
        setIsMounted(false);
      }, slideDurationMs);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isMounted) {
      setIsOpenInternal(isOpen);
    }
  }, [isMounted]);

  if (shouldUnmountOnClose && !isMounted) {
    return null;
  }

  return (
    <>
      <DrawerWrapper {...rest} isOpen={isOpenInternal} slideDurationMs={slideDurationMs}>
        <DrawerContainer className={className} widthPx={widthPx}>
          <DrawerHeader>
            <ContainerTitle>
              <ButtonCloseDrawer
                type="button"
                size="medium"
                icon={X}
                data-testid="button-close-drawer"
                onClick={onCloseDrawer}
              />

              <Heading size="H3" data-testid="drawer-title">
                {title}
              </Heading>
            </ContainerTitle>

            {headerActions && <DrawerHeaderActions>{headerActions}</DrawerHeaderActions>}
          </DrawerHeader>

          {children}

          {footerActions && <DrawerFooterActions>{footerActions}</DrawerFooterActions>}
        </DrawerContainer>

        {nested}
      </DrawerWrapper>

      {!hideBackdrop && (
        <Fade in={isOpen} timeout={slideDurationMs}>
          <WindowShadow
            data-testid="backdrop-drawer"
            onClick={shouldCloseOnBackdropClick ? onCloseDrawer : null}
          />
        </Fade>
      )}

      {isMounted && <HideBodyScrollbar />}
    </>
  );
};

export default Root;
