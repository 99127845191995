import { LoadingBuzz } from '@hexa-ui/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@tanstack/react-query';
import { TypeToast, useAppHeader, useToast } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { getRule } from '../../api/services/rule/Rule';
import { FormRuleCreation } from '../../components/FormRuleCreation/FormRuleCreation';
import { ModalCustom } from '../../components/Modal';
import { ModalProps } from '../../components/Modal/Modal.d';
import { FormFieldRule } from '../../consts/formField';
import { QUERY_KEY_RULE } from '../../consts/query';
import { PATH } from '../../consts/route';
import { RuleType, TargetType } from '../../consts/rule';
import { useSegmentRuleUpdate } from '../../hooks/useSegmentRuleUpdate';
import { useUpsertRule } from '../../hooks/useUpsertRule';
import { useUserInfo } from '../../hooks/useUserInfo';
import { FormRuleCreationData, RuleOperation } from '../../models/rule';
import { RuleChanges } from '../../models/segment';
import { ruleSchema } from '../../schemas/rule';
import { CustomHeaderCenter } from '../../styles/GlobalStyles';
import { convertExpressionToRuleOperations } from '../../utils/expression';
import {
  AlertInfo,
  ButtonRuleUpdate,
  ButtonWrapper,
  RuleUpdateCard,
  RuleUpdateContainer,
} from './RuleUpdate.styles';

export const RuleUpdate = (): JSX.Element => {
  const { ruleId } = useParams();
  const { formatMessage } = useIntl();
  const { executeUpsertRule } = useUpsertRule(true);
  const { triggerSegmentEventStarted, triggerSegmentEventFinished } = useSegmentRuleUpdate();

  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const [searchParams] = useSearchParams();
  const { selectedVendor } = userInfo;

  const toastService = useToast();
  const [, setAppHeaderConfig] = useAppHeader();

  const methods = useForm({
    resolver: yupResolver(ruleSchema),
  });
  const { handleSubmit, reset, watch } = methods;

  const [rule, setRule] = useState<FormRuleCreationData>(null);
  const isRuleNameEquals = watch(FormFieldRule.Name) === rule?.name;
  const isRuleFormulaEquals =
    JSON.stringify(normalizeRuleOperation(watch(FormFieldRule.Operations) as RuleOperation[])) ===
    JSON.stringify(normalizeRuleOperation(rule?.operations));

  const [modalSaveRuleName, setModalSaveRuleName] = useState<ModalProps>({
    buttonSecondaryLabel: formatMessage({
      id: `ruleEdit.modal.changeRuleName.buttonSecondaryLabel`,
    }),
    buttonPrimaryLabel: formatMessage({
      id: `ruleEdit.modal.changeRuleName.buttonPrimaryLabel`,
    }),
    description: formatMessage({
      id: `ruleEdit.modal.changeRuleName.description`,
    }),
    title: formatMessage({ id: `ruleEdit.modal.changeRuleName.title` }),
    isOpen: false,
  });

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: formatMessage({ id: 'ruleEdit.title' }),
      optionalButtons: [],
      breadcrumbConfig: {
        homePath: PATH.SWITCH,
        items: [
          {
            label: formatMessage({ id: 'home.title' }),
            path: `${PATH.RULE_LIST}`,
            isCurrentPage: false,
          },
          {
            label: formatMessage({ id: 'ruleEdit.title' }),
            path: `${PATH.RULE_UPDATE}`,
            isCurrentPage: true,
          },
        ],
      },
    });
  }, [setAppHeaderConfig]);

  const { data: dataRequestRule, isLoading: isLoadingRequestRule } = useQuery(
    [
      QUERY_KEY_RULE,
      {
        ruleId: ruleId,
      },
    ],
    ({ signal }) =>
      getRule({
        ruleId: ruleId,
        signal,
      }),
    {
      onError: () => {
        toastService.notify({
          type: TypeToast.ERROR,
          message: 'Error',
        });
      },
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  function normalizeRuleOperation(ruleOperation: RuleOperation[]) {
    ruleOperation?.map((operation) => {
      operation.value = Number(operation.value);
      operation.items?.map((item) => {
        delete item['labelId'];
      });
      operation.categories?.map((category) => {
        delete category['attributeId'];
      });
    });

    return ruleOperation;
  }

  /* istanbul ignore next */
  function onInvalid(errors: FieldErrors<FormRuleCreationData>) {
    toastService.notify({
      type: TypeToast.WARNING,
      message: formatMessage({ id: 'ruleCreation.toast.fieldsRequiredWarning' }),
    });
  }

  /* istanbul ignore next */
  function handleOnClickSaveRuleChanges() {
    if (!isRuleFormulaEquals) {
      setModalSaveRuleName({
        buttonSecondaryLabel: formatMessage({
          id: `ruleEdit.modal.changeRuleFormula.buttonSecondaryLabel`,
        }),
        buttonPrimaryLabel: formatMessage({
          id: `ruleEdit.modal.changeRuleFormula.buttonPrimaryLabel`,
        }),
        description: formatMessage({
          id: `ruleEdit.modal.changeRuleFormula.description`,
        }),
        title: formatMessage({ id: `ruleEdit.modal.changeRuleFormula.title` }),
        isOpen: true,
      });
    } else {
      setModalSaveRuleName({ ...modalSaveRuleName, isOpen: true });
    }
  }

  /* istanbul ignore next */
  function handleConfirmRuleNameChange() {
    let isSuccessful = true;
    try {
      executeUpsertRule({
        ruleId: ruleId,
        vendorId: selectedVendor,
        name: watch(FormFieldRule.Name),
        recognitionModelId: rule.recognitionModelId,
        operations: watch(FormFieldRule.Operations) as RuleOperation[],
        type: rule.type,
      });
    } catch (error) {
      isSuccessful = false;
    } finally {
      const ruleChanges = () => {
        if (!isRuleNameEquals && !isRuleFormulaEquals) {
          return RuleChanges.Name_Formula;
        }
        if (!isRuleNameEquals) {
          return RuleChanges.Name;
        }
        if (!isRuleFormulaEquals) {
          return RuleChanges.Formula;
        }
      };
      triggerSegmentEventFinished({
        is_successful: isSuccessful,
        rule_id: ruleId,
        rule_changes: ruleChanges().toString(),
      });
    }
  }

  function handleCancelRuleUpdate() {
    navigate({ pathname: PATH.RULE_LIST, search: searchParams.toString() });
  }

  async function convertExpression(vendorId, trainedModelId, expression, ruleType) {
    return await convertExpressionToRuleOperations(vendorId, trainedModelId, expression, ruleType);
  }

  useEffect(() => {
    if (dataRequestRule) {
      const ruleType =
        dataRequestRule.data.targetType == TargetType.Sku ? RuleType.Sku : RuleType.Advertisement;
      convertExpression(
        selectedVendor,
        dataRequestRule.data.expressions[0].trainedModelId,
        dataRequestRule.data.expressions[0].expression,
        ruleType
      ).then((result) => {
        const rule = {
          [FormFieldRule.Name]: dataRequestRule.data.description,
          [FormFieldRule.RecognitionModelId]: dataRequestRule.data.expressions[0].trainedModelId,
          [FormFieldRule.UpdatedBy]: dataRequestRule.data.updatedBy,
          [FormFieldRule.UpdatedAt]: dataRequestRule.data.updatedAt,
          [FormFieldRule.Type]: ruleType,
          [FormFieldRule.Operations]: result,
        };
        reset(rule);
        setRule(rule);
      });
    }
  }, [dataRequestRule]);

  useEffectOnce(() => {
    triggerSegmentEventStarted({ rule_id: ruleId });
  });

  if (isLoadingRequestRule) {
    return <LoadingBuzz dataTestId="loading-buz-component" size="xlarge" color="mono" />;
  }
  return (
    <RuleUpdateContainer data-testid="rule-update-container">
      <CustomHeaderCenter />

      <FormProvider {...methods}>
        <form
          onSubmit={(event) => void handleSubmit(handleOnClickSaveRuleChanges, onInvalid)(event)}
          data-testid="form-rule-creation"
        >
          <RuleUpdateCard border={'small'} elevated={'minimal'} data-testid="rule-update-card">
            <FormRuleCreation isUpdateDisable={true}></FormRuleCreation>
          </RuleUpdateCard>
          <ButtonWrapper data-testid="rule-update-button-wrapper">
            <ButtonRuleUpdate
              size="large"
              type="button"
              onClick={handleCancelRuleUpdate}
              variant="secondary"
              data-testid="cancel-rule-update-button"
            >
              {formatMessage({ id: 'ruleEdit.buttons.cancel' })}
            </ButtonRuleUpdate>
            <ButtonRuleUpdate
              size="large"
              type="submit"
              variant="primary"
              data-testid="button-rule-update"
              disabled={isRuleNameEquals && isRuleFormulaEquals}
            >
              {formatMessage({ id: 'ruleEdit.buttons.update' })}
            </ButtonRuleUpdate>
            <ModalCustom
              buttonPrimaryLabel={modalSaveRuleName.buttonPrimaryLabel}
              buttonSecondaryLabel={modalSaveRuleName.buttonSecondaryLabel}
              description={modalSaveRuleName.description}
              title={modalSaveRuleName.title}
              handleConfirm={() => handleConfirmRuleNameChange()}
              isOpen={modalSaveRuleName.isOpen}
              onClose={() => setModalSaveRuleName({ ...modalSaveRuleName, isOpen: false })}
              data-testid="modal-save-rule-name"
            >
              {!isRuleFormulaEquals && (
                <AlertInfo
                  type="info"
                  message={formatMessage({ id: `ruleEdit.modal.changeRuleFormula.info` })}
                />
              )}
            </ModalCustom>
          </ButtonWrapper>
        </form>
      </FormProvider>
    </RuleUpdateContainer>
  );
};

export default RuleUpdate;
