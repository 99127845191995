import { Paragraph, Select } from '@hexa-ui/components';
import styled from 'styled-components';

export const ContainerSelect = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const InputSelect = styled.div``;
export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 252px;
`;
export const ToolTipContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px 0px;
`;

export const IconContainer = styled.div`
  display: flex;
`;

export const LabelCustom = styled(Paragraph)`
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.positive.color_06};
`;

export const InfoOption = styled(Select.Option)``;

export const Hr = styled.hr``;
