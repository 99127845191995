import { Divider, Heading } from '@hexa-ui/components';
import styled from 'styled-components';
import { NestedDrawerContainerProps } from './Nested.d';

export const NestedDrawerContainer = styled.div<NestedDrawerContainerProps>`
  position: absolute;
  top: 0;
  right: ${(props) =>
    props.isOpen ? `${props.widthPx}px` : `-${props.rootWidthPx - props.widthPx}px`};
  z-index: 997;
  display: flex;
  flex-direction: column;
  width: ${(props) => `${props.widthPx}px`};
  height: 100%;
  padding: 32px 56px 32px 32px;
  background-color: rgb(255, 255, 255);
  border-radius: 16px 0px 0px 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 8px;
  transition: right ${(props) => `${props.slideDurationMs}ms`};
`;

export const NestedDrawerHeader = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

export const HeaderTitle = styled(Heading)``;

export const HeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const HeaderDivider = styled(Divider)`
  margin-top: 12px;
`;

export const NestedDrawerContent = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
