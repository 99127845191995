import { useLayoutEffect, useState } from 'react';
import { useClampedProps } from './useClamped.d';

export const useClamped = ({ elementRef }: useClampedProps) => {
  const [isClamped, setIsClamped] = useState(false);

  useLayoutEffect(() => {
    if (elementRef?.current) {
      const element = elementRef.current;
      const elementTotalHeight = element.scrollHeight;
      const elementVisibleHeight = element.clientHeight + 2;
      const isClamped = elementTotalHeight > elementVisibleHeight;
      if (isClamped) {
        setIsClamped(true);
      }
    }
  }, [elementRef]);

  return {
    isClamped,
  };
};
