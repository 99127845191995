import { UserInfoProviderProps } from './useUserInfo.d';

export function useUserInfo(): UserInfoProviderProps {
  const userInfoStored = localStorage.getItem('userInfo');

  if (userInfoStored && Object.keys(userInfoStored).length > 0) {
    return JSON.parse(userInfoStored) as UserInfoProviderProps;
  }

  return undefined;
}
