export enum FormFieldRule {
  Name = 'name',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  RecognitionModelId = 'recognitionModelId',
  Operations = 'operations',
}

export enum FormFieldRuleOperation {
  Method = 'method',
  Operator = 'operator',
  Value = 'value',
  Count = 'count',
  Condition = 'condition',
  Items = 'items',
  ItemType = 'itemType',
  FunctionType = 'functionType',
  Categories = 'categories',
}
