import { Paragraph, Select } from '@hexa-ui/components';
import { Info } from '@hexa-ui/icons';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormulaFunctionType } from '../../consts/formulaOperation';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import IntersectionIcon from './../../assets/icons/intersection.svg';
import UnionIcon from './../../assets/icons/union.svg';
import { FunctionSelectorProps } from './FunctionSelector.d';
import {
  ContainerSelect,
  IconContainer,
  InputSelect,
  LabelContainer,
  LabelCustom,
  ToolTipContainer,
} from './FunctionSelector.styles';

export const FunctionSelector = ({ onChange, value }: FunctionSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [functionSelected, setFunctionSelected] = useState<FormulaFunctionType>(value);
  const [functionSelectedLabel, setFunctionSelectedLabel] = useState<string>(null);

  function handleOnSelectChange(value: FormulaFunctionType): void {
    setFunctionSelected(value);
    setFunctionSelectedLabel(
      formatMessage({
        id: 'ruleCreation.form.selectFunction.' + functionSelected + '.placeHolder',
      })
    );

    onChange(value);
  }

  return (
    <ContainerSelect data-testid="container-function-model-select">
      <LabelContainer data-testid="container-function-model-select-label">
        <LabelCustom weight="medium">
          {formatMessage({
            id: 'ruleCreation.form.selectFunction.' + functionSelected + '.label1',
          })}{' '}
        </LabelCustom>
        <Paragraph weight="medium">
          {formatMessage({
            id: 'ruleCreation.form.selectFunction.' + functionSelected + '.label2',
          })}
        </Paragraph>
        <ToolTipContainer>
          <CustomTooltip
            placement="bottom"
            text={formatMessage({
              id: 'ruleCreation.form.selectFunction.' + functionSelected + '.tooltip',
            })}
          >
            <IconContainer>
              <Info />
            </IconContainer>
          </CustomTooltip>
        </ToolTipContainer>
      </LabelContainer>

      <InputSelect>
        <Select.Root
          name="function-model"
          id="select-function-model"
          data-testid="select-function-model"
          value={functionSelected}
          placeholder={functionSelectedLabel}
          size="small"
          icon={
            <img
              src={functionSelected == FormulaFunctionType.Union ? UnionIcon : IntersectionIcon}
            />
          }
          onChange={(value) => handleOnSelectChange(value)}
        >
          <Select.Option data-testid="union-option" value={FormulaFunctionType.Union}>
            {formatMessage({
              id: `ruleCreation.form.selectFunction.${FormulaFunctionType.Union}.placeHolder`,
            })}
          </Select.Option>
          <Select.Option data-testid="intersection-option" value={FormulaFunctionType.Intersect}>
            {formatMessage({
              id: `ruleCreation.form.selectFunction.${FormulaFunctionType.Intersect}.placeHolder`,
            })}
          </Select.Option>
        </Select.Root>
      </InputSelect>
    </ContainerSelect>
  );
};
