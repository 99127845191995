import { Select } from '@hexa-ui/components';
import styled from 'styled-components';

export const ContainerSelect = styled.div`
  width: 30%;
  min-width: 300px;
`;

export const InfoOption = styled(Select.Option)``;

export const InfoLabel = styled.span`
  border-top: 1px solid '#000';
  color: ${({ theme }) => theme.colors.alert.color_05};
`;

export const Hr = styled.hr``;
