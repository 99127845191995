import { Heading, Modal, Paragraph } from '@hexa-ui/components';
import { ModalProps } from './Modal.d';
import { ModalButton, ModalButtonsWrapper, ModalContainer } from './Modal.styles';

export const ModalCustom = ({
  buttonPrimaryLabel,
  buttonSecondaryLabel,
  handleConfirm,
  title,
  description,
  isOpen,
  onClose,
  children,
}: ModalProps): JSX.Element => {
  return (
    <Modal.Root
      headerHeight="auto"
      actions={
        <ModalContainer data-testid="modal-custom-container">
          <ModalButtonsWrapper>
            <Modal.Cancel>
              <ModalButton
                data-testid="modal-cancel-button"
                size="medium"
                variant="secondary"
                onClick={onClose}
              >
                {buttonSecondaryLabel}
              </ModalButton>
            </Modal.Cancel>
            <ModalButton
              size="medium"
              variant="primary"
              onClick={(event) => {
                handleConfirm();
                event.preventDefault();
              }}
              data-testid="modal-confirm-button"
            >
              {buttonPrimaryLabel}
            </ModalButton>
          </ModalButtonsWrapper>
        </ModalContainer>
      }
      title={<Heading>{title}</Heading>}
      open={isOpen}
    >
      <Paragraph>{description}</Paragraph>
      {children}
    </Modal.Root>
  );
};
