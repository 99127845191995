/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface ChangesApplied {
	/**
	 * 
The ID given to the country.
	 */
	country_id: string | null
	/**
	 * Agreggation of [NAME, FORMULA, NAME_FORMULA] to identify which modifcation was applied in this action
	 */
	rule_changes: string | null
	/**
	 * The config id & Empty
	 */
	rule_id: string | null
	/**
	 * A unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The exact time the user tapped rule creation finished button
	 */
	time_stamp_finished: string | null
	/**
	 * The user's vendor ID.
	 */
	vendor_id: string | null
}
export interface EasyCopyApplied {
	/**
	 * 
The ID given to the country.
	 */
	country_id: string | null
	/**
	 * The config id & Empty
	 */
	rule_id: string | null
	/**
	 * A unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The user's vendor ID.
	 */
	vendor_id: string | null
}
export interface FilterApplicationSourcesItem {
	/**
	 * The source of application, Sample: FORCE, REWARDS, FORCE_REWARDS.
	 */
	application_source: string | null
}
export interface FilterModelTypesItem {
	/**
	 * The model types filter selected Sample.: SKU or Poster
	 */
	model_type?: string | null
}
export interface FilterApplied {
	/**
	 * 
The ID given to the country.
	 */
	country_id: string | null
	/**
	 * The array of filter application sources. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	filter_application_sources: FilterApplicationSourcesItem[] | null
	/**
	 * The filter range date
	 */
	filter_creation_on: string | null
	/**
	 * The array of filter model types. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	filter_model_types?: FilterModelTypesItem[] | null
	/**
	 * A unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The user's vendor ID.
	 */
	vendor_id: string | null
}
export interface RuleCreationFinished {
	/**
	 * The source of application, Sample: FORCE, REWARDS, FORCE_REWARDS
	 */
	application_source: ApplicationSource
	/**
	 * 
The ID given to the country.
	 */
	country_id: string | null
	/**
	 * If Rule Creation Successful = True; If not = False
	 */
	is_successful: boolean | null
	/**
	 * The rule model selected by the users, Sample: SKU, POSTER
	 */
	model_type: ModelType
	/**
	 * How many operations the rule expression contains
	 */
	operation_quantity: number | null
	/**
	 * The config id & Empty
	 */
	rule_id: string | null
	/**
	 * The name given to the rule
	 */
	rule_name: string | null
	/**
	 * A unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The exact time the user tapped rule creation finished button
	 */
	time_stamp_finished: string | null
	/**
	 * The user's vendor ID.
	 */
	vendor_id: string | null
}
export interface RuleCreationStarted {
	/**
	 * 
The ID given to the country.
	 */
	country_id: string | null
	/**
	 * A unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The exact time the user tapped rule creation started button
	 */
	time_stamp_started: string | null
	/**
	 * The user's vendor ID.
	 */
	vendor_id: string | null
}
export interface RuleListStarted {
	/**
	 * 
The ID given to the country.
	 */
	country_id: string | null
	/**
	 * A unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The exact time the user tapped rule creation started button
	 */
	time_stamp_started: string | null
	/**
	 * The user's vendor ID.
	 */
	vendor_id: string | null
}
export interface RuleSearched {
	/**
	 * 
The ID given to the country.
	 */
	country_id: string | null
	/**
	 * A unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The rule_name or rule_id created searched
	 */
	text_searched: string | null
	/**
	 * The user's vendor ID.
	 */
	vendor_id: string | null
}
export interface RuleViewAndEditStarted {
	/**
	 * 
The ID given to the country.
	 */
	country_id: string | null
	/**
	 * The config id & Empty
	 */
	rule_id: string | null
	/**
	 * A unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The exact time the user tapped rule creation started button
	 */
	time_stamp_started: string | null
	/**
	 * The user's vendor ID.
	 */
	vendor_id: string | null
}

export enum ApplicationSource {
	Force = 'FORCE',
	Rewards = 'REWARDS',
	Null = 'null',
}
export enum ModelType {
	Poster = 'POSTER',
	Sku = 'SKU',
	Null = 'null',
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef ChangesApplied
 * @property {string | null} country_id - 
The ID given to the country.
 * @property {string | null} rule_changes - Agreggation of [NAME, FORMULA, NAME_FORMULA] to identify which modifcation was applied in this action
 * @property {string | null} rule_id - The config id & Empty
 * @property {string | null} session_id - A unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} time_stamp_finished - The exact time the user tapped rule creation finished button 
 * @property {string | null} vendor_id - The user's vendor ID.
 */
/**
 * @typedef EasyCopyApplied
 * @property {string | null} country_id - 
The ID given to the country.
 * @property {string | null} rule_id - The config id & Empty
 * @property {string | null} session_id - A unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} vendor_id - The user's vendor ID.
 */
/**
 * @typedef FilterApplicationSourcesItem
 * @property {string | null} application_source - The source of application, Sample: FORCE, REWARDS, FORCE_REWARDS.
 */
/**
 * @typedef FilterModelTypesItem
 * @property {string | null} [model_type] - The model types filter selected Sample.: SKU or Poster
 */
/**
 * @typedef FilterApplied
 * @property {string | null} country_id - 
The ID given to the country.
 * @property {FilterApplicationSourcesItem[] | null} filter_application_sources - The array of filter application sources. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} filter_creation_on - The filter range date
 * @property {FilterModelTypesItem[] | null} [filter_model_types] - The array of filter model types. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} session_id - A unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} vendor_id - The user's vendor ID.
 */
/**
 * @typedef RuleCreationFinished
 * @property {ApplicationSource} application_source - The source of application, Sample: FORCE, REWARDS, FORCE_REWARDS
 * @property {string | null} country_id - 
The ID given to the country.
 * @property {boolean | null} is_successful - If Rule Creation Successful = True; If not = False 
 * @property {ModelType} model_type - The rule model selected by the users, Sample: SKU, POSTER
 * @property {number | null} operation_quantity - How many operations the rule expression contains
 * @property {string | null} rule_id - The config id & Empty
 * @property {string | null} rule_name - The name given to the rule
 * @property {string | null} session_id - A unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} time_stamp_finished - The exact time the user tapped rule creation finished button 
 * @property {string | null} vendor_id - The user's vendor ID.
 */
/**
 * @typedef RuleCreationStarted
 * @property {string | null} country_id - 
The ID given to the country.
 * @property {string | null} session_id - A unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} time_stamp_started - The exact time the user tapped rule creation started button
 * @property {string | null} vendor_id - The user's vendor ID.
 */
/**
 * @typedef RuleListStarted
 * @property {string | null} country_id - 
The ID given to the country.
 * @property {string | null} session_id - A unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} time_stamp_started - The exact time the user tapped rule creation started button
 * @property {string | null} vendor_id - The user's vendor ID.
 */
/**
 * @typedef RuleSearched
 * @property {string | null} country_id - 
The ID given to the country.
 * @property {string | null} session_id - A unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} text_searched - The rule_name or rule_id created searched
 * @property {string | null} vendor_id - The user's vendor ID.
 */
/**
 * @typedef RuleViewAndEditStarted
 * @property {string | null} country_id - 
The ID given to the country.
 * @property {string | null} rule_id - The config id & Empty
 * @property {string | null} session_id - A unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} time_stamp_started - The exact time the user tapped rule creation started button
 * @property {string | null} vendor_id - The user's vendor ID.
 */

/**
 * When the user apply any changes and save it
 *
 * @param {ChangesApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changesApplied(
	props: ChangesApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Changes Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Whe the user clicks on the easy copy button in order to copy the rule searched or filtered.
 *
 * @param {EasyCopyApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function easyCopyApplied(
	props: EasyCopyApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Easy Copy Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user apply filters.
 *
 * @param {FilterApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filterApplied(
	props: FilterApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Filter Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on the create rule button in order to finish the process of creating a new rule.
 *
 * @param {RuleCreationFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleCreationFinished(
	props: RuleCreationFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Rule Creation Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on the + New rule button in order to start the process of creating a new rule.
 *
 * @param {RuleCreationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleCreationStarted(
	props: RuleCreationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Rule Creation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user access the 
Rule List page.
 *
 * @param {RuleListStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleListStarted(
	props: RuleListStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Rule List Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Whe the user filters the current Rule List entering a keyword in the search box .
 *
 * @param {RuleSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleSearched(
	props: RuleSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Rule Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on the edition rule button in order to start the process of editing a new rule
 *
 * @param {RuleViewAndEditStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ruleViewAndEditStarted(
	props: RuleViewAndEditStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Rule View and Edit Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * When the user apply any changes and save it
	 *
	 * @param {ChangesApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changesApplied,
	/**
	 * Whe the user clicks on the easy copy button in order to copy the rule searched or filtered.
	 *
	 * @param {EasyCopyApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	easyCopyApplied,
	/**
	 * When the user apply filters.
	 *
	 * @param {FilterApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filterApplied,
	/**
	 * When the user clicks on the create rule button in order to finish the process of creating a new rule.
	 *
	 * @param {RuleCreationFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleCreationFinished,
	/**
	 * When the user clicks on the + New rule button in order to start the process of creating a new rule.
	 *
	 * @param {RuleCreationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleCreationStarted,
	/**
	 * When the user access the 
	Rule List page.
	 *
	 * @param {RuleListStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleListStarted,
	/**
	 * Whe the user filters the current Rule List entering a keyword in the search box .
	 *
	 * @param {RuleSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleSearched,
	/**
	 * When the user clicks on the edition rule button in order to start the process of editing a new rule
	 *
	 * @param {RuleViewAndEditStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ruleViewAndEditStarted,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2O42B6IxexLQUfIYBBceKWR6rmq`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
