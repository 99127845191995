import { v4 as uuidv4 } from 'uuid';
import segmentAnalytics from '../../../analytics';
import { SegmentCommonProps } from '../../models/segment';
import { useUserInfo } from '../useUserInfo';
import { SegmentRuleUpdateFinished, SegmentRuleUpdateStarted } from './useSegmentRuleUpdate.d';

export const useSegmentRuleUpdate = () => {
  const userInfo = useUserInfo();
  const commonProps: SegmentCommonProps = {
    vendor_id: userInfo.selectedVendor,
    country_id: userInfo.selectedCountry,
    session_id: uuidv4(),
  };

  const triggerSegmentEventStarted = (segmentRuleUpdateStarted: SegmentRuleUpdateStarted) => {
    const props = {
      ...commonProps,
      ...segmentRuleUpdateStarted,
      time_stamp_started: new Date().toISOString(),
    };
    segmentAnalytics.ruleViewAndEditStarted(props);
  };

  const triggerSegmentEventFinished = (segmentRuleUpdateFinished: SegmentRuleUpdateFinished) => {
    const props = {
      ...commonProps,
      ...segmentRuleUpdateFinished,
      time_stamp_finished: new Date().toISOString(),
    };
    segmentAnalytics.changesApplied(props);
  };

  return {
    triggerSegmentEventStarted,
    triggerSegmentEventFinished,
  };
};
