import { AxiosResponse } from 'axios';
import { ENDPOINT_GET_ALL_TRAINED_MODELS } from '../../../consts/endpoint';
import { api } from '../../api';
import { GetTrainedModels } from './interface';

export const getTrainedModels = async ({
  vendorId,
  signal,
}: GetTrainedModels): Promise<AxiosResponse> =>
  api.get({
    url: `${ENDPOINT_GET_ALL_TRAINED_MODELS}`,
    config: {
      params: {
        pageSize: -1,
        vendorId,
      },
      signal,
    },
  });
