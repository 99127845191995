import {
  createFeatureToggleServiceV2,
  useAppHeader,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import {
  OPTIMIZELY_PROJECT_BEES_ONTAP,
  OPTIMIZELY_VISION_RULES_HUB_ACCESS,
} from '../../consts/optimizely';
import { useOptimizelyVariables } from '../../hooks/useOptimizelyVariables/useOptimizelyVariables';
import { useUserInfo } from '../../hooks/useUserInfo';
import { OptimizelyConfig } from '../../models/optimizely';
import { useEnvContext } from '../EnvProvider';
import { AppHeaderProviderProps } from './AppHeaderProvider.d';

export const AppHeaderProvider = ({ children }: AppHeaderProviderProps): JSX.Element => {
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const { selectedCountry, selectedVendor, loadingVendors } = appHeaderConfig;
  const { data: metadata, isLoading: metaDataIsLoading } = useUserMetadata();
  const { OPTIMIZELY_KEY } = useEnvContext();
  const [firstLoad, setFirstLoad] = useState(true);

  const optimizelyConfig: OptimizelyConfig = {
    projectName: OPTIMIZELY_PROJECT_BEES_ONTAP,
    featureName: OPTIMIZELY_VISION_RULES_HUB_ACCESS,
    sdkKey: OPTIMIZELY_KEY,
  };
  if (
    window.services?.FeatureToggleOptimizelyV2 &&
    window.services?.FeatureToggleOptimizelyV2[OPTIMIZELY_PROJECT_BEES_ONTAP]
  ) {
  } else {
    createFeatureToggleServiceV2(optimizelyConfig.projectName, optimizelyConfig.sdkKey);
  }

  const visionRulesHubAccessFeature = useOptimizelyVariables({ optimizelyConfig });

  useEffect(() => {
    const user = useUserInfo();

    if (user != undefined) {
      setAppHeaderConfig({
        countryOptions: user.availableCountries,
        defaultVendor: user.selectedVendor,
        defaultCountry: user.selectedCountry,
        vendorSelect: true,
        countrySelect: true,
      });
    }
  }, [setAppHeaderConfig]);

  useEffect(() => {
    if (
      !!metadata &&
      !!metadata?.supportedCountries &&
      !!metadata?.vendors &&
      !!visionRulesHubAccessFeature?.variables
    ) {
      let optimizelyCountries = [];
      let optimizelyCountriesDomainEU = [];

      if (!metaDataIsLoading) {
        const user = useUserInfo();

        optimizelyCountries = visionRulesHubAccessFeature?.variables
          .find((variable) => variable.key === 'countries')
          .value.split(',');

        optimizelyCountriesDomainEU = visionRulesHubAccessFeature?.variables
          .find((variable) => variable.key === 'countriesDomainEU')
          ?.value.split(',');

        let countriesWithVendors = metadata.vendors.map((vendor) => vendor.country);
        optimizelyCountries = optimizelyCountries.filter((country) =>
          countriesWithVendors.includes(country)
        );

        if (Object.keys(user).length === 0) {
          setAppHeaderConfig({
            countryOptions: optimizelyCountries,
            vendorSelect: true,
            countrySelect: true,
          });
        }

        user.vendorName = metadata.vendors.find(
          (vendor) => vendor.id === user.selectedVendor
        )?.displayName;

        if (optimizelyCountries) {
          user.availableCountries = optimizelyCountries;
        }

        if (optimizelyCountriesDomainEU) {
          user.countriesDomainEU = optimizelyCountriesDomainEU;
        }

        localStorage.setItem('userInfo', JSON.stringify(user));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, visionRulesHubAccessFeature?.variables]);

  useEffect(() => {
    let user = useUserInfo();

    if (selectedCountry && selectedVendor) {
      setFirstLoad(false);
      user.selectedVendor = selectedVendor;
    }

    if (user == undefined) {
      user = {};
    }

    if (selectedCountry) {
      user.selectedCountry = selectedCountry;
    }

    if (!loadingVendors && selectedVendor) {
      user.selectedVendor = selectedVendor;
    }

    localStorage.setItem('userInfo', JSON.stringify(user));

    if (!firstLoad) {
      location.reload();
    }
  }, [selectedCountry, selectedVendor]);

  return children;
};
