import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PATH } from '../consts/route';
import { RuleUpdate } from '../pages/RuleUpdate';
import PageLoading from '../pages/PageLoading';

const HomePage = lazy(() => import('../pages/HomePage/HomePage'));
const RuleCreation = lazy(() => import('../pages/RuleCreation/RuleCreation'));

const FallbackPage = <PageLoading />;

export const Router = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={PATH.RULE_LIST}
        element={
          <Suspense fallback={FallbackPage}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path={PATH.RULE_CREATE}
        element={
          <Suspense fallback={FallbackPage}>
            <RuleCreation />
          </Suspense>
        }
      />
      <Route
        path={`${PATH.RULE_UPDATE({ ruleId: ':ruleId' })}`}
        element={
          <Suspense fallback={FallbackPage}>
            <RuleUpdate />
          </Suspense>
        }
      />
    </Routes>
  );
};
