import { ItemType } from './rule';

export interface ItemOption {
  itemType: ItemType;
  shouldRender: boolean;
}

export enum FormulaMethod {
  OperationCount = 'OPERATION_COUNT',
  OperationShare = 'OPERATION_SHARE',
}

export enum FormulaOperator {
  EqualsTo = '=',
  GreaterThan = '>',
  GreaterOrEqualsTo = '>=',
  LessThan = '<',
  LessOrEqualsTo = '<=',
}

export enum FormulaCondition {
  And = 'AND',
  Or = 'OR',
}

export enum FormulaFunctionType {
  Union = 'UNION',
  Intersect = 'INTERSECT',
  All = 'ALL',
}
