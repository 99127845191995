import { Divider, Heading, IconButton, Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';

interface SelectedLabelTitleProps {
  $lineClamp?: number;
}

export const WrapperSelectedLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerSelectedLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-top: 18px;
`;

export const SelectedLabelInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectedLabelTitle = styled(Heading)<SelectedLabelTitleProps>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ $lineClamp: lineClamp }) => lineClamp};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const TextTooltip = styled.div`
  word-break: break-all;
`;

export const SelectedLabelDesc = styled(Paragraph)``;

export const SelectedLabelActions = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonRemoveSelectedLabel = styled(IconButton)``;

export const DividerSelectedLabel = styled(Divider)`
  margin-top: 18px;
`;
