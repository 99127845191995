import { Badge, Button, Checkbox, Paragraph, TextButton } from '@hexa-ui/components';
import styled from 'styled-components';
import { CustomScrollbar } from '../../styles/GlobalStyles';

export const ContainerHeaderActionButtons = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContainerFooterActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const ButtonCancel = styled(Button)``;

export const ButtonConfirm = styled(Button)``;

export const ContainerSelectionActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  gap: 12px;
`;

export const ButtonClearSelection = styled(TextButton)`
  color: ${(theme) => theme.theme.colors.positive.color_06};
`;

export const ButtonAddSelection = styled(Button)`
  flex-shrink: 0;
  white-space: nowrap;
`;

export const NestedHeaderActions = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const NestedHeaderSubActions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  white-space: nowrap;
`;

export const ButtonRemoveAll = styled(TextButton)`
  color: ${(theme) => theme.theme.colors.positive.color_06};
`;

export const ContainerSelectedLabels = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: auto;

  ${CustomScrollbar}
`;

export const ContainerCategoryGroups = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  padding-right: 8px;
  overflow: auto;
  ${CustomScrollbar}
`;

export const ButtonCategoryGroup = styled.button`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  cursor: pointer;
`;

export const ContainerInfoCategoryGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 16px 0 16px 8px;
`;

export const TitleCategoryGroup = styled(Paragraph)`
  text-transform: capitalize;
`;

export const ContainerActionsCategoryGroup = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const CounterCategoryGroup = styled(Badge.Counter)`
  padding: 4px;
`;

export const ButtonOpenCategoryGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerCategory = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonHeaderCategory = styled.button`
  display: flex;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
`;

export const ButtonCloseCategoryGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-right: 8px;
`;

export const TitleCategory = styled(Paragraph)`
  text-transform: capitalize;
`;

export const ContainerActionsCategory = styled.div`
  margin-left: auto;
`;

export const CounterCategory = styled(Badge.Counter)`
  padding: 4px;
`;

export const ContainerCategoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;
  text-transform: capitalize;
  max-height: 400px;
  padding-right: 8px;
  overflow: auto;
  ${CustomScrollbar}
`;

export const CheckboxCategory = styled(Checkbox)``;

export const ContainerSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ContainerSkeletonLabels = styled(ContainerSkeleton)`
  padding: 8px 0 0 0;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 100px 46px;
`;

export const ConfirmEdition = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
`;
export const ConfirmEditionActions = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
  justify-content: space-between;
`;

export const ConfirmEditionSubTitle = styled.div`
  padding: 5px 0px;
`;

export const ButtonCancelEdition = styled(Button)``;

export const ButtonConfirmEdition = styled(Button)``;

export const ContainerErrorCategories = styled.div``;
