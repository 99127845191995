export const DEBUG_INITIAL_IS_OPEN = false;
export const STALE_TIME_MS = 5 * 60 * 1000;
export const RETRY_TIMES = 3;
export const RETRY_DELAY_MIN_MS = 2 * 1000;
export const RETRY_DELAY_MAX_MS = 10 * 1000;

export const QUERY_KEY_TRAINED_MODELS = 'trained-models';
export const QUERY_KEY_LABELS = 'label-list';
export const QUERY_KEY_CATEGORIES = 'categories';
export const QUERY_KEY_RULES = 'rule-list';
export const QUERY_KEY_RULE = 'rule';

export enum SortAt {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum PaginationParamSearch {
  Page = 'page',
  Size = 'size',
}
