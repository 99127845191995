import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { ItemType } from '../../consts/rule';
import { useClamped } from '../../hooks/useClamped';
import { CustomTooltip } from '../CustomTooltip';
import { LabelItemProps } from './LabelItem.d';
import {
  AlignedCheckbox,
  LabelItemCheckboxComponent,
  LabelItemSelectContainer,
  LabelQuantity,
  LabelTitle,
  TextTooltip,
} from './LabelItem.styles';

export const LabelItem = ({
  label,
  checkedLabels,
  setCheckedLabels,
  selectedItemType,
  selectedLabelsIds,
  labelsLength,
  setSelectAllLabels,
}: LabelItemProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const titleRef = useRef<HTMLHeadingElement>(null);
  const { isClamped: isTitleClamped } = useClamped({ elementRef: titleRef });

  const isSelectedItemTypeSku = selectedItemType === ItemType.Sku;
  const displayLabel = label.commercialName ?? label.labelName;

  const handleCheckboxChange = (checkboxId) => {
    const isChecked = checkedLabels.includes(checkboxId);

    if (isChecked) {
      const updatedCheckedLabels = checkedLabels.filter((id) => id !== checkboxId);
      if (updatedCheckedLabels.length !== labelsLength) {
        setSelectAllLabels(false);
      }
      setCheckedLabels(updatedCheckedLabels);
    } else {
      const updatedCheckedLabels = [...checkedLabels, checkboxId];
      if (updatedCheckedLabels.length === labelsLength) {
        setSelectAllLabels(true);
      }
      setCheckedLabels(updatedCheckedLabels);
    }
  };

  return (
    <LabelItemSelectContainer key={label.labelName} data-testid="container-label-item">
      <LabelItemCheckboxComponent>
        <AlignedCheckbox
          checked={checkedLabels.includes(label.labelName)}
          value={label.labelName}
          onCheckedChange={() => handleCheckboxChange(label.labelName)}
          id={label.labelName}
          disabled={selectedLabelsIds?.includes(label.labelName)}
          data-testid="checkbox-label"
        />
        <CustomTooltip
          text={
            (
              <TextTooltip data-testid="label-tooltip">{label.labelName}</TextTooltip>
            ) as unknown as string
          }
          active={isTitleClamped}
          testId="selected-label-tooltip"
        >
          <LabelTitle
            $lineClamp={isSelectedItemTypeSku ? 1 : 2}
            ref={titleRef}
            size="H5"
            htmlFor={label.labelName}
          >
            {displayLabel}
          </LabelTitle>
        </CustomTooltip>
      </LabelItemCheckboxComponent>

      {selectedItemType === ItemType.Sku && (
        <LabelQuantity selectable="false" size="small" weight="normal" colortype="secondary">
          {formatMessage(
            {
              id: `ruleCreation.drawer.selectLabels.unit.${
                label.quantity > 1 ? 'plural' : 'singular'
              }`,
            },
            {
              quantity: label.quantity,
              labelName: label.labelName,
            }
          )}
        </LabelQuantity>
      )}
    </LabelItemSelectContainer>
  );
};
