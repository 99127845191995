import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { useSegmentAnalytics } from './hooks/useSegmentAnalytics';
import { AppHeaderProvider } from './providers/AppHeaderProvider/AppHeaderProvider';
import { AppThemeProvider } from './providers/AppThemeProvider';
import { EnvConfig, EnvProvider } from './providers/EnvProvider';
import { IntlProvider } from './providers/IntlProvider';
import { QueryProvider } from './providers/QueryProvider';
import { Router } from './Router';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-vision-rules-mfe',
    seed: 'bees-hub-vision-rules-mfe',
  });

  useSegmentAnalytics(props.SEGMENT_KEY);

  return (
    <QueryProvider>
      <EnvProvider env={props}>
        <StylesProvider generateClassName={generateClassName}>
          <AppThemeProvider>
            <IntlProvider>
              <AppHeaderProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </AppHeaderProvider>
            </IntlProvider>
          </AppThemeProvider>
        </StylesProvider>
      </EnvProvider>
    </QueryProvider>
  );
}
