import { Button } from '@hexa-ui/components';
import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  gap: 1em;
`;

export const ModalButton = styled(Button)`
  margin-right: '0.9375rem';
`;
