import { AxiosResponse } from 'axios';
import { ENDPOINT_GET_CATEGORIES, ENDPOINT_POST_LABELS } from '../../../consts/endpoint';
import { FormulaFunctionType } from '../../../consts/formulaOperation';
import { Category, Label } from '../../../models/rule';
import { api } from '../../api';
import { GetCategories, PostLabelsPayload } from './interface';

export const getLabels = async ({
  trainedModelId,
  vendorId,
  type = FormulaFunctionType.All,
  attributeValues,
}: PostLabelsPayload): Promise<AxiosResponse<Label[]>> =>
  api.post({
    url: `${ENDPOINT_POST_LABELS}`,
    body: {
      type,
      attributeValues,
      excludeAttributeValues: [{ attributeName: 'VENDOR_OWNERSHIP', attributeValues: ['NO'] }],
    },
    config: {
      params: {
        vendorId,
        trainedModelId,
      },
    },
  });

export const getCategories = async ({
  trainedModelId,
  vendorId,
  signal,
}: GetCategories): Promise<AxiosResponse<Category[]>> =>
  api.get({
    url: `${ENDPOINT_GET_CATEGORIES}`,
    config: {
      signal,
      params: {
        vendorId,
        trainedModelId,
        excludeAttributes: 'VENDOR_OWNERSHIP',
      },
    },
  });
