import { Checkbox, Heading, Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';

interface LabelTitleProps {
  $lineClamp?: number;
}

export const LabelItemSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 18px 10px 18px 0;
`;

export const LabelItemCheckboxComponent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AlignedCheckbox = styled(Checkbox)`
  align-self: baseline;
`;

export const LabelTitle = styled(Heading)<LabelTitleProps>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ $lineClamp: lineClamp }) => lineClamp};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const LabelQuantity = styled(Paragraph)`
  margin-left: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`;

export const TextTooltip = styled.div`
  word-break: break-all;
`;
