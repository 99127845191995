import { Alert, Button, Card } from '@hexa-ui/components';
import styled from 'styled-components';
import { ScreenBreakpoint } from '../../consts/screen';

export const RuleUpdateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 24px;
  margin: auto;
  width: 1300px;
  @media (max-width: ${ScreenBreakpoint.DesktopMedium}) {
    margin: 0;
    width: 100%;
  }
  @media (max-width: ${ScreenBreakpoint.Mobile}) {
    margin: 0;
    min-width: 420px;
  }
`;

export const RuleUpdateCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px 32px 40px;

  @media (max-width: ${ScreenBreakpoint.Mobile}) {
    padding: 24px 16px 24px;
    margin: 0px 25px 0px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
  padding: 40px 0px 15px 105px;
`;

export const ButtonRuleUpdate = styled(Button)``;

export const AlertInfo = styled(Alert)`
  width: 100%;
  margin-top: 1%;
`;
