import { v4 as uuidv4 } from 'uuid';
import { getLabels } from '../../api/services/labelList/LabelList';
import {
  FormulaCondition,
  FormulaFunctionType,
  FormulaMethod,
  FormulaOperator,
} from '../../consts/formulaOperation';
import { ItemType, RuleType } from '../../consts/rule';
import { ExpressionType } from '../../consts/ruleExpression';
import { Category, Label, RuleOperation } from '../../models/rule';

export async function convertExpressionToRuleOperations(
  vendorId: string,
  trainedModelId: string,
  expression: string,
  ruleType: RuleType
): Promise<RuleOperation[]> {
  const ruleOperations: RuleOperation[] = [];

  const delimiterLogical = /( AND | OR )/;
  const delimiterMethod = /(OPERATION_COUNT|OPERATION_SHARE)/;
  const delimiterOperator = /(<=|>=|=|<|>)/;

  const operationLogical = expression.split(delimiterLogical);

  for (let i = 0; i < operationLogical.length; i++) {
    let currentExpression = operationLogical[i];

    if (currentExpression.match(delimiterLogical)) continue;

    let ruleOperation = {} as RuleOperation;

    const expressionOperator = currentExpression.split(delimiterOperator);

    let expressionType = expressionOperator[0].trim().split(delimiterMethod);
    ruleOperation.operator = expressionOperator[1] as FormulaOperator;
    ruleOperation.method = expressionType[1] as FormulaMethod;
    ruleOperation.value =
      ruleOperation.method == FormulaMethod.OperationShare
        ? Math.floor(parseFloat(expressionOperator[2]) * 100)
        : parseFloat(expressionOperator[2]);

    let expression = expressionType[2];

    switch (true) {
      case expression.startsWith('(labelName'):
        ruleOperation.items = [parseLabelName(expression)];
        ruleOperation.itemType = ruleType === RuleType.Sku ? ItemType.Sku : ItemType.Poster;
        ruleOperation.count = ruleOperation.items.length;
        break;
      case expression.startsWith('(SUM'):
        ruleOperation.itemType = ruleType === RuleType.Sku ? ItemType.Sku : ItemType.Poster;
        ruleOperation.items = parseSum(expression);
        ruleOperation.count = ruleOperation.items.length;
        break;
      case expression.startsWith('(UNION'):
        ruleOperation.itemType = ItemType.Categories;
        ruleOperation.categories = parseUnion(expression);
        ruleOperation.functionType = FormulaFunctionType.Union;
        await setRuleOperation(ruleOperation, vendorId, trainedModelId);
        break;
      case expression.startsWith('(INTERSECT'):
        ruleOperation.itemType = ItemType.Categories;
        ruleOperation.categories = parseIntersection(expression);
        ruleOperation.functionType = FormulaFunctionType.Intersect;
        await setRuleOperation(ruleOperation, vendorId, trainedModelId);
        break;
      case expression.startsWith('(VENDOR_OWNERSHIP'):
        ruleOperation.itemType = ItemType.Competition;
        break;
      case expression.startsWith('('):
        ruleOperation.itemType = ItemType.Categories;
        ruleOperation.categories = parseCategory(expression.replace(/[()']/g, '').split(','));
        ruleOperation.functionType = FormulaFunctionType.Union;
        await setRuleOperation(ruleOperation, vendorId, trainedModelId);
        break;
    }

    if (i > 1) {
      ruleOperation.condition = operationLogical[i - 1].trim() as FormulaCondition;
    }

    ruleOperations.push(ruleOperation);
  }

  return ruleOperations;
}

function parseLabelName(expression: string): Label {
  const labelName = expression.replace(/[()']/g, '').split('.')[1];
  return { labelId: uuidv4(), labelName: labelName, quantity: 1 } as Label;
}

function parseSum(expression: string): Label[] {
  const labels = expression.substring(4).replace(/[()']/g, '').split(',');
  return labels.map((label) => {
    return parseLabelName(label);
  });
}

function parseUnion(expression: string): Category[] {
  const union = expression.substring(6).replace(/[()']/g, '').split(',');
  return parseCategory(union);
}

function parseIntersection(expression: string): Category[] {
  const intersect = expression
    .substring(10)
    .replace(/[()']/g, '')
    .replaceAll('UNION', '')
    .split(',');
  return parseCategory(intersect);
}

function parseCategory(expression: string[]): Category[] {
  let categories: Category[] = [];
  expression.forEach((attributeAndValue) => {
    const [attributeName, attributeValue] = attributeAndValue.trim().split('.');
    const category = categories.find((obj) => obj.attributeName === attributeName);
    if (category) {
      category.attributeValues.push(attributeValue);
    } else {
      categories.push({
        attributeName: attributeName,
        attributeValues: [attributeValue],
      } as Category);
    }
  });
  return categories;
}

async function setRuleOperation(
  ruleOperation: RuleOperation,
  vendorId: string,
  trainedModelId: string
) {
  await getLabels({
    trainedModelId,
    vendorId,
    type: ruleOperation.functionType,
    attributeValues: ruleOperation.categories,
  }).then((result) => {
    ruleOperation.items = result.data;
    ruleOperation.count = result.data.length;
  });
}

export function convertRuleOperationsToExpression(operations: RuleOperation[]): string {
  const expression = operations.reduce((expression, currentOperation) => {
    const { condition, itemType } = currentOperation;
    let currentExpression: string;

    if (itemType === ItemType.Categories) {
      currentExpression = convertRuleCategoryOperationToExpression(currentOperation);
    }
    if (itemType === ItemType.Sku || itemType === ItemType.Poster) {
      currentExpression = convertRuleLabelOperationToExpression(currentOperation);
    }
    if (itemType === ItemType.Competition) {
      currentExpression = convertRuleCompetitionToExpression(currentOperation);
    }

    const hasCondition = !!condition;
    if (hasCondition) {
      currentExpression = ` ${condition} ${currentExpression}`;
    }

    return expression + currentExpression;
  }, '');

  return expression;
}

export function convertRuleLabelOperationToExpression(operation: RuleOperation): string {
  const { items, method, operator } = operation;
  let expression: string;
  let valueExpression = getValueExpression(operation);

  const labels = items.map((item) => `labelName.'${item.labelName}'`).join(', ');
  const sumLabels = `${ExpressionType.Sum}(${labels})`;
  const hasMultipleLabels = items.length > 1;
  const labelsExpression = hasMultipleLabels ? sumLabels : labels;
  expression = `${method}(${labelsExpression}) ${operator} ${valueExpression}`;

  return expression;
}

export function convertRuleCategoryOperationToExpression(operation: RuleOperation): string {
  const { method, operator, categories, functionType } = operation;

  const isFunctionTypeMissing = !functionType;
  if (isFunctionTypeMissing) {
    throw new Error('functionType is required');
  }

  const isCategoriesMissing = !categories;
  if (isCategoriesMissing) {
    throw new Error('categories are required');
  }

  let expression: string;
  let valueExpression = getValueExpression(operation);
  const hasMultipleCategories = categories.length > 1;
  const isFunctionTypeIntersect = functionType === FormulaFunctionType.Intersect;
  const isFunctionTypeUnion = functionType === FormulaFunctionType.Union;

  const categoriesExpression = categories
    .map(({ attributeName, attributeValues }) => {
      let categoryExpression: string;
      const valuesExpression = attributeValues
        .map((value) => `${attributeName}.'${value}'`)
        .join(', ');

      if (isFunctionTypeIntersect) {
        const hasMultipleValues = attributeValues.length > 1;
        if (hasMultipleValues) {
          categoryExpression = `${ExpressionType.Union}(${valuesExpression})`;
        } else {
          categoryExpression = valuesExpression;
        }
      }

      if (isFunctionTypeUnion) {
        if (hasMultipleCategories) {
          categoryExpression = valuesExpression;
        } else {
          const hasMultipleValues = attributeValues.length > 1;
          if (hasMultipleValues) {
            categoryExpression = `${ExpressionType.Union}(${valuesExpression})`;
          } else {
            categoryExpression = valuesExpression;
          }
        }
      }

      return categoryExpression;
    })
    .join(', ');

  const functionTypeExpression = hasMultipleCategories
    ? `${functionType}(${categoriesExpression})`
    : categoriesExpression;

  expression = `${method}(${functionTypeExpression}) ${operator} ${valueExpression}`;
  return expression;
}

export function convertRuleCompetitionToExpression(operation: RuleOperation): string {
  const { method, operator } = operation;
  let expression: string;
  let valueExpression = getValueExpression(operation);

  const competitionExpression = 'VENDOR_OWNERSHIP.NO';
  expression = `${method}(${competitionExpression}) ${operator} ${valueExpression}`;

  return expression;
}

function getValueExpression(operation: RuleOperation): string {
  const { method, value } = operation;
  let valueExpression: string;

  const isOperationShare = method === FormulaMethod.OperationShare;
  if (isOperationShare) {
    valueExpression = `${value / 100}`;
  } else {
    valueExpression = `${value}`;
  }

  return valueExpression;
}
