import { AxiosResponse } from 'axios';
import { ENDPOINT_GET_RULE } from '../../../consts/endpoint';
import { api } from '../../api';
import { GetRule, GetRuleBasePayload } from './Rule.d';

export const getRule = async ({
  headers,
  ruleId,
  signal,
}: GetRule): Promise<AxiosResponse<GetRuleBasePayload>> =>
  api.get({
    url: ENDPOINT_GET_RULE({ ruleId }),
    config: {
      headers,
      signal,
    },
  });
