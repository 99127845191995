import { SkeletonLoaderContainer } from './SkeletonLoaderSku.styles';
import { SkeletonLoader } from '@hexa-ui/components';

export const SkeletonLoaderSku = (): JSX.Element => {
  return (
    <SkeletonLoaderContainer data-testid="skeleton-loader-sku">
      <SkeletonLoader height="40px" imageShape="rectangle" variant="body" width="calc(100%)" />
      <SkeletonLoader height="80px" imageShape="rectangle" variant="body" width="calc(100%)" />
      <SkeletonLoader height="80px" imageShape="rectangle" variant="body" width="calc(100%)" />
      <SkeletonLoader height="80px" imageShape="rectangle" variant="body" width="calc(100%)" />
      <SkeletonLoader height="80px" imageShape="rectangle" variant="body" width="calc(100%)" />
    </SkeletonLoaderContainer>
  );
};
