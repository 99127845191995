import { Trash2 } from '@hexa-ui/icons';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { ItemType } from '../../consts/rule';
import { useClamped } from '../../hooks/useClamped';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { SelectedLabelProps } from './SelectedLabel.d';
import {
  ButtonRemoveSelectedLabel,
  ContainerSelectedLabel,
  DividerSelectedLabel,
  SelectedLabelActions,
  SelectedLabelDesc,
  SelectedLabelInfo,
  SelectedLabelTitle,
  TextTooltip,
  WrapperSelectedLabel,
} from './SelectedLabel.styles';

export const SelectedLabel = ({
  label,
  isRemovable = false,
  onRemove,
  hideDescription = false,
  selectedItemType,
}: SelectedLabelProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const { isClamped: isTitleClamped } = useClamped({ elementRef: titleRef });

  const isItemTypeSku = selectedItemType === ItemType.Sku;
  const displayLabel = label.commercialName ?? label.labelName;

  return (
    <WrapperSelectedLabel data-testid="selected-label">
      <ContainerSelectedLabel key={label.labelId}>
        <SelectedLabelInfo>
          <CustomTooltip
            text={
              (
                <TextTooltip data-testid="label-tooltip">{label.labelName}</TextTooltip>
              ) as unknown as string
            }
            active={isTitleClamped}
            testId="selected-label-tooltip"
          >
            <SelectedLabelTitle
              $lineClamp={isItemTypeSku ? 1 : 2}
              size="H5"
              ref={titleRef}
              data-testid="selected-label-title"
            >
              {displayLabel}
            </SelectedLabelTitle>
          </CustomTooltip>

          {!hideDescription && (
            <SelectedLabelDesc colortype="secondary" data-testid="selected-label-desc">
              {formatMessage(
                {
                  id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.unit.${
                    label.quantity > 1 ? 'plural' : 'singular'
                  }`,
                },
                {
                  quantity: label.quantity,
                  labelName: label.labelName,
                }
              )}
            </SelectedLabelDesc>
          )}
        </SelectedLabelInfo>
        <SelectedLabelActions>
          {isRemovable && (
            <ButtonRemoveSelectedLabel
              data-testid="button-remove-selected-label"
              variant="tertiary"
              type="button"
              icon={() => <Trash2 />}
              onClick={() => onRemove(label)}
            />
          )}
        </SelectedLabelActions>
      </ContainerSelectedLabel>

      <DividerSelectedLabel />
    </WrapperSelectedLabel>
  );
};
