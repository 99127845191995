import { Checkbox, Paragraph } from '@hexa-ui/components';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ItemType } from '../../consts/rule';
import { LabelItem } from '../LabelItem';
import { LabelListSelectorProps } from './LabelListSelector.d';
import {
  ContainerLabelsList,
  HeaderSelectAll,
  Hr,
  LabelItemCheckboxComponent,
} from './LabelListSelector.styles';

export const LabelListSelector = ({
  labels,
  selectedItemType,
  checkedLabels,
  setCheckedLabels,
  checkedAllLabels,
  setCheckedAllLabels,
  addedLabelsIds,
  uncheckAllLabels,
  searchResults,
}: LabelListSelectorProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const labelsType = selectedItemType === ItemType.Poster ? 'posters' : 'SKUs';
  const quantityLabels = `${labels.length} ${labelsType}`;
  const quantityResults = searchResults === null ? 0 : searchResults?.length;
  const allLabelsAdded =
    addedLabelsIds.length === labels?.length || addedLabelsIds.length === searchResults?.length;

  const items = searchResults === null ? labels : searchResults;

  const handleSelectAll = () => {
    if (!checkedAllLabels) {
      const allLabelNames = items.map((label) => label.labelName);
      const newCheckedLabels = checkedLabels.concat(
        allLabelNames.filter((labelName) => !checkedLabels.includes(labelName))
      );
      const allLabelsSelected = newCheckedLabels.length === allLabelNames.length;

      setCheckedLabels(newCheckedLabels);
      setCheckedAllLabels(!allLabelsSelected);
    } else {
      uncheckAllLabels();
    }
  };

  useEffect(() => {
    const allLabelsSelected =
      checkedLabels.length === labels.length || checkedLabels.length === searchResults?.length;
    setCheckedAllLabels(allLabelsSelected);
  }, [items, labels]);

  return (
    <>
      <HeaderSelectAll>
        <LabelItemCheckboxComponent>
          <Checkbox
            disabled={allLabelsAdded}
            checked={checkedAllLabels}
            onCheckedChange={handleSelectAll}
            value="all"
            id="select-all-labels"
            data-testid="check-all-labels"
          />
          <Paragraph
            colortype="secondary"
            size="small"
            weight="semibold"
            htmlFor="select-all-labels"
          >
            {formatMessage({ id: 'ruleCreation.drawer.selectLabels.checkbox.selectAll' })}
          </Paragraph>
        </LabelItemCheckboxComponent>

        <Paragraph colortype="secondary" size="small" weight="semibold" htmlFor="select-all-labels">
          {quantityResults === 0
            ? quantityLabels
            : `${quantityResults} ${formatMessage({
                id: 'ruleCreation.drawer.selectLabels.results',
              })}`}
        </Paragraph>
      </HeaderSelectAll>

      <Hr />
      <ContainerLabelsList data-testid="label-list-selector">
        {items?.map((item) => {
          return (
            <LabelItem
              selectedLabelsIds={addedLabelsIds}
              checkedLabels={checkedLabels}
              label={item}
              setCheckedLabels={setCheckedLabels}
              key={item.labelId}
              selectedItemType={selectedItemType}
              setSelectAllLabels={setCheckedAllLabels}
              labelsLength={labels.length}
            />
          );
        })}
      </ContainerLabelsList>
    </>
  );
};
