import { Tooltip } from '@hexa-ui/components';
import { TooltipProps } from '@hexa-ui/components/dist/declarations/src/lib/tooltip/Tooltip/Tooltip.types';
import { TooltipContainer } from './CustomTooltip.styles';

type CustomTooltipProps = {
  active?: boolean;
  testId?: string;
} & TooltipProps;

export const CustomTooltip = ({
  children,
  active = true,
  testId = 'custom-tooltip',
  ...props
}: CustomTooltipProps): JSX.Element => {
  return active ? (
    <TooltipContainer data-testid={testId}>
      <Tooltip {...props}>{children}</Tooltip>
    </TooltipContainer>
  ) : (
    <>{children}</>
  );
};
