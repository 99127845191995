import { Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';
import { RuleType } from '../../consts/rule';
import { ButtonProps, IconWrapperProps } from './RuleTypeButton.d';

export const RuleTypeButtonContainer = styled.div``;

export const Button = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 23px 24px;
  gap: 8px;
  min-height: 123px;
  min-width: 136px;
  border-radius: 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }

  border: ${(props) => {
    if (props.isActive && props.disabled) return `4px solid ${props.theme.colors.neutral.color_04}`;
    if (props.disabled) return `1px solid ${props.theme.colors.neutral.color_04}`;
    if (props.hasError) return `1px solid ${props.theme.colors.alert.color_05}`;
    if (props.isActive) return '4px solid #047AF1';

    return '4px solid transparent';
  }};
  ${(props) => {
    if (props.disabled)
      return {
        boxShadow: 'none',
        opacity: 0.3,
      };
  }}
`;

export const LabelText = styled(Paragraph)`
  font-size: 16px;
  font-weight: 500;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  ${(props) => {
    if (props.disabled)
      return {
        backgroundColor: props.theme.colors.neutral.color_04,
      };
    const iconWrapperBg = {
      [RuleType.Advertisement]: {
        backgroundColor: props.theme.colors.focus.color_01,
      },
      [RuleType.Sku]: {
        backgroundColor: props.theme.colors.accent.b.color_04,
      },
      default: {
        backgroundColor: props.theme.colors.primary.background,
      },
    };
    return iconWrapperBg[props.ruleTypeId];
  }}

  width: 52px;
  height: 52px;
  border-radius: 50%;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 30px;
`;
