export interface SegmentCommonProps {
  vendor_id: string;
  country_id: string;
  session_id: string;
}

export enum ModelType {
  Poster = 'Poster',
  Sku = 'SKU',
  Null = 'null',
}

export enum RuleChanges {
  Name = 'NAME',
  Formula = 'FORMULA',
  Name_Formula = 'NAME_FORMULA',
}
