import { AxiosResponse } from 'axios';
import { ENDPOINT_POST_RULE_ID } from '../../../consts/endpoint';
import { api } from '../../api';
import { PostRuleIdPayload, PostRuleIdResponse } from './RuleCreation.d';

export const postRuleId = async ({
  vendorId,
  ruleId,
  description,
  validationMode,
  targetType,
  expressionCombination,
  expressions,
}: PostRuleIdPayload): Promise<AxiosResponse<PostRuleIdResponse>> =>
  api.post({
    url: `${ENDPOINT_POST_RULE_ID}`,
    body: {
      ruleId,
      vendorId,
      description,
      validationMode,
      targetType,
      expressionCombination,
      expressions,
    },
  });
