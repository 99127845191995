import { useUserInfo } from '../hooks/useUserInfo';

enum environment {
  UAT = 'uat',
  SIT = 'qa',
  PROD = 'prod',
}

const SERVICES_DOMAIN_US = {
  [environment.PROD]: 'https://one.bees-platform.com',
  [environment.UAT]: 'https://one-uat.bees-platform.dev',
  [environment.SIT]: 'https://one-sit.bees-platform.dev',
};

const SERVICES_DOMAIN_EU = {
  [environment.PROD]: 'https://services.eu.mybees-platform.com',
  [environment.UAT]: 'https://services.eu.uat.restricted.mybees-platform.dev',
  [environment.SIT]: 'https://one-sit.bees-platform.dev',
};

const env = JSON.parse(localStorage.getItem('portal-config'))?.ENV;

const userInfo = useUserInfo();

/* istanbul ignore next */
export const SERVICES_DOMAIN = userInfo?.countriesDomainEU?.includes(userInfo?.selectedCountry)
  ? SERVICES_DOMAIN_EU[env]
  : SERVICES_DOMAIN_US[env];

export const SERVICE_VISION_CONFIGURATION = `${SERVICES_DOMAIN ?? ''}/api/vision-configuration`;

export const ENDPOINT_GET_ALL_TRAINED_MODELS = `${SERVICE_VISION_CONFIGURATION}/trained-models`;
export const ENDPOINT_GET_ALL_RULES = `${SERVICE_VISION_CONFIGURATION}/rules`;

export const ENDPOINT_GET_RULE = ({ ruleId }: { ruleId: string }): string =>
  `${ENDPOINT_POST_RULE_ID}/${ruleId}`;

export const ENDPOINT_POST_LABELS = `${SERVICE_VISION_CONFIGURATION}/labels`;
export const ENDPOINT_GET_CATEGORIES = `${SERVICE_VISION_CONFIGURATION}/label-attributes/label-values`;

export const ENDPOINT_POST_RULE_ID = `${SERVICE_VISION_CONFIGURATION}/rules`;
