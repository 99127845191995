import { useMutation } from '@tanstack/react-query';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { postRuleId } from '../../api/services/ruleCreation/RuleCreation';
import { PostRuleIdPayload } from '../../api/services/ruleCreation/RuleCreation.d';
import { PATH } from '../../consts/route';
import { TargetType, ValidationMode } from '../../consts/rule';
import { FormRuleCreationData } from '../../models/rule';
import { convertRuleOperationsToExpression } from '../../utils/expression';

export function useUpsertRule(isUpdate: boolean = false) {
  const navigate = useNavigate();
  const toastService = useToast();
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();

  const { mutateAsync: mutateAsyncRule } = useMutation({
    mutationFn: postRuleId,
    onSuccess: (rule) => {
      showRuleNameUpsertedSuccessfully(rule.data.ruleId, formatMessage({ id: 'toast.info.copy' }));

      navigate({ pathname: PATH.RULE_LIST, search: searchParams.toString() });
    },
  });

  function onError(): void {
    toastService.notify({
      type: TypeToast.ERROR,
      message: formatMessage({
        id: 'toast.error.request',
      }),
    });
  }

  function showRuleNameUpsertedSuccessfully(keyId, actionName) {
    toastService.notify({
      actions: [
        {
          name: actionName,
          action: function () {
            /* istanbul ignore next */
            if (actionName === 'copy') {
              navigator.clipboard.writeText(keyId);
              showRuleIdCopied();
            }
          },
        },
      ],
      type: TypeToast.SUCCESS,

      message: isUpdate
        ? formatMessage({ id: 'toast.success.update' }, { keyId })
        : formatMessage({ id: 'toast.success.request' }) + `\n\n ${keyId}`,
    });
  }

  /* istanbul ignore next */
  function showRuleIdCopied() {
    toastService.notify({
      type: TypeToast.INFO,
      message: formatMessage({
        id: 'toast.info.copyRuleId',
      }),
    });
  }

  const executeUpsertRule = async (formRuleCreationData: FormRuleCreationData) => {
    try {
      const expression = convertRuleOperationsToExpression(formRuleCreationData.operations);
      const alias =
        formRuleCreationData.type === TargetType.Sku ? 'SKU_MAIN_MODEL' : 'POP_MAIN_MODEL';
      const trimmedRuleName = formRuleCreationData.name.trim();

      await mutateAsyncRule({
        ruleId: formRuleCreationData.ruleId,
        vendorId: formRuleCreationData.vendorId,
        description: trimmedRuleName,
        validationMode: ValidationMode.Prediction,
        targetType:
          formRuleCreationData.type === TargetType.Sku ? TargetType.Sku : TargetType.Poster,
        expressionCombination: `(${alias})`,
        expressions: [
          {
            trainedModelId: formRuleCreationData.recognitionModelId,
            expression: expression,
            alias: alias,
          },
        ],
      } as PostRuleIdPayload);
    } catch (error) {
      onError();
    }
  };

  return {
    executeUpsertRule,
  };
}
