import { useAuthenticationService, useFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import { useState } from 'react';
import { OptimizelyVariablesProps, OptimizelyVariablesResult } from './useOptimizelyVariables.d';

export function useOptimizelyVariables({ optimizelyConfig }: OptimizelyVariablesProps) {
  const [optimizelyVariables, setOptimizelyVariables] =
    useState<OptimizelyVariablesResult>(undefined);
  const [optimizelyVariablesLoaded, setOptimizelyVariablesLoaded] = useState<boolean>(false);

  const authenticationService = useAuthenticationService();
  const userId = authenticationService.getUserId();
  const vendorId = authenticationService.getVendorId();

  const featureToggleService = useFeatureToggleServiceV2(optimizelyConfig.projectName);
  if (optimizelyVariablesLoaded) return optimizelyVariables;
  featureToggleService.onReady().then(() => {
    featureToggleService
      .isFeatureEnabledAudiences(optimizelyConfig.featureName, userId, {
        vendorId,
      })
      .then((featureToggleStatus: boolean) => {
        setOptimizelyVariablesLoaded(true);

        const config = featureToggleService['optimizely'].projectConfigManager.configObj;
        const variableKeys = config.featureKeyMap[optimizelyConfig.featureName].variables;

        let availableFeatureVariables = [];

        config.flagVariationsMap[optimizelyConfig.featureName]
          .map((feature) => feature.variables)
          .forEach((fields) => {
            fields.forEach((field) => {
              const variable = variableKeys.find((variableKey) => variableKey.id === field.id);
              availableFeatureVariables.push({ ...variable, value: field.value });
            });
          });

        setOptimizelyVariables({
          featureEnabled: featureToggleStatus,
          variables: availableFeatureVariables,
        });
      })
      .catch((error) => console.error(error));
  });

  return optimizelyVariables;
}
