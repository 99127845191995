import { Divider, Heading, Paragraph, SkeletonLoader } from '@hexa-ui/components';
import { ChevronLeft, ChevronRight, Trash2 } from '@hexa-ui/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getCategories, getLabels } from '../../api/services/labelList/LabelList';
import { FormFieldRule } from '../../consts/formField';
import { FormulaFunctionType } from '../../consts/formulaOperation';
import { QUERY_KEY_CATEGORIES, QUERY_KEY_LABELS } from '../../consts/query';
import { ItemType } from '../../consts/rule';
import { useUserInfo } from '../../hooks/useUserInfo';
import { Category, RuleOperation } from '../../models/rule';
import Drawer from '../Drawer';
import { FunctionSelector } from '../FunctionSelector';
import { SelectedLabel } from '../SelectedLabel/SelectedLabel';
import { DrawerSelectCategoriesProps } from './DrawerSelectCategories.d';
import {
  ButtonAddSelection,
  ButtonCancel,
  ButtonCancelEdition,
  ButtonCategoryGroup,
  ButtonClearSelection,
  ButtonCloseCategoryGroup,
  ButtonConfirm,
  ButtonConfirmEdition,
  ButtonHeaderCategory,
  ButtonOpenCategoryGroup,
  ButtonRemoveAll,
  CheckboxCategory,
  ConfirmEdition,
  ConfirmEditionActions,
  ConfirmEditionSubTitle,
  ContainerActionsCategory,
  ContainerActionsCategoryGroup,
  ContainerButtons,
  ContainerCategory,
  ContainerCategoryGroups,
  ContainerCategoryList,
  ContainerFooterActionButtons,
  ContainerHeaderActionButtons,
  ContainerInfo,
  ContainerInfoCategoryGroup,
  ContainerSelectedLabels,
  ContainerSelectionActions,
  ContainerSkeleton,
  ContainerSkeletonLabels,
  CounterCategory,
  CounterCategoryGroup,
  NestedHeaderActions,
  NestedHeaderSubActions,
  TitleCategory,
  TitleCategoryGroup,
} from './DrawerSelectCategories.styles';

export const DrawerSelectCategories = ({
  isOpen,
  onClose,
  selectedTrainedModelId,
  ruleType,
  operationIndex,
}: DrawerSelectCategoriesProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const toastService = useToast();
  const { setValue, clearErrors, getValues } = useFormContext();
  const [openCategoryGroup, setOpenCategoryGroup] = useState<Category>(null);
  const [showOverwriteCategories, setShowOverwriteCategories] = useState(false);

  const formOperationPath = `${FormFieldRule.Operations}.${operationIndex}`;

  const [selectedCategories, setSelectedCategories] = useState<Category[]>(
    getValues(formOperationPath)?.categories ?? []
  );

  const [addSelectedCategories, setAddSelectedCategories] =
    useState<Category[]>(selectedCategories);

  const [functionType, setFunctionType] = useState<FormulaFunctionType>(
    getValues(formOperationPath)?.functionType ?? FormulaFunctionType.Union
  );

  const [isOpenNestedDrawerSelectedLabels, setIsOpenNestedDrawerSelectedLabels] = useState(false);
  const userInfo = useUserInfo();
  const { selectedVendor } = userInfo;
  const isDisabledCategoryActionButtons = selectedCategories.length === 0;
  const drawerSlideDurationMs = 1000;
  const nestedDrawerSlideDurationMs = 1000;

  function onChangeFunctionType(functionOption: FormulaFunctionType) {
    setFunctionType(functionOption);
  }

  function resetToInitialState() {
    const operation = getValues(formOperationPath);
    setOpenCategoryGroup(null);
    setSelectedCategories(operation.categories ?? []);
    setFunctionType(operation.functionType ?? FormulaFunctionType.Union);
  }

  function onCloseDrawer() {
    setIsOpenNestedDrawerSelectedLabels(false);
    !!onClose && onClose();

    setTimeout(() => {
      resetToInitialState();
    }, drawerSlideDurationMs);
  }

  async function updatedSelectedCategories(
    notify = true,
    categories = selectedCategories,
    type = functionType
  ) {
    try {
      const labels = await mutateAsyncLabels({
        trainedModelId: selectedTrainedModelId,
        vendorId: selectedVendor,
        type: type,
        attributeValues: categories,
      });

      const labelsLength = labels.data?.length;

      const hasLabels = labelsLength > 0;
      if (hasLabels && notify) {
        const hasOneLabel = labelsLength === 1;
        const messageIdBasedOnLabelCount = hasOneLabel ? 'singular' : 'plural';

        toastService.notify({
          type: TypeToast.INFO,
          message: formatMessage(
            {
              id: `ruleCreation.drawer.selectLabels.toast.labelsAdded.${messageIdBasedOnLabelCount}`,
            },
            {
              quantity: labelsLength,
            }
          ),
        });
      }

      setIsOpenNestedDrawerSelectedLabels(true);
      setShowOverwriteCategories(false);
    } catch (error) {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'toast.error.default',
        }),
      });
    }
  }

  async function handleAddCategories() {
    setAddSelectedCategories(selectedCategories);
    updatedSelectedCategories();
  }

  async function handlePreAddCategories() {
    if (hasSelectedLabels) {
      setShowOverwriteCategories(true);
    } else {
      handleAddCategories();
    }
  }

  async function handleCancelPreAddCategories() {
    setShowOverwriteCategories(false);
  }

  function handleClearCategorySelection() {
    setSelectedCategories([]);
  }

  function handleRemoveAllLabels() {
    setIsOpenNestedDrawerSelectedLabels(false);
    setSelectedCategories([]);
    setTimeout(() => {
      resetLabels();
    }, nestedDrawerSlideDurationMs);
  }

  function handleOpenCategoryGroup(category: Category) {
    setOpenCategoryGroup(category);
  }

  function handleCloseCategoryGroup() {
    setOpenCategoryGroup(null);
  }

  function handleFinishSelection() {
    const formOperationPath = `${FormFieldRule.Operations}.${operationIndex}`;
    const formPathItems = `${formOperationPath}.items`;
    const formPathCategories = `${formOperationPath}.categories`;
    const updatedOperation: RuleOperation = getValues(formOperationPath);

    updatedOperation.count = labels.data.length;

    updatedOperation.categories = addSelectedCategories;
    updatedOperation.functionType = functionType;
    clearErrors(formPathItems);
    clearErrors(formPathCategories);
    delete updatedOperation.items;

    setValue(formOperationPath, updatedOperation);

    onCloseDrawer();
  }

  function addCategory({ categoryGroup, category }: { categoryGroup: Category; category: string }) {
    const updatedCategoryGroup = cloneDeep(categoryGroup);
    updatedCategoryGroup.attributeValues = [category];
    const updatedSelectedCategories = cloneDeep(selectedCategories);

    const hasCategoryGroupAdded = selectedCategories.some(
      (currentCategoryGroup) => currentCategoryGroup.attributeName === categoryGroup.attributeName
    );
    if (hasCategoryGroupAdded) {
      const categoryGroupIndex = updatedSelectedCategories.findIndex(
        (currentCategoryGroup) => currentCategoryGroup.attributeName === categoryGroup.attributeName
      );
      updatedSelectedCategories[categoryGroupIndex].attributeValues.push(category);
    } else {
      updatedSelectedCategories.push(updatedCategoryGroup);
    }

    setSelectedCategories(updatedSelectedCategories);
  }

  function removeCategory({
    categoryGroup,
    category,
  }: {
    categoryGroup: Category;
    category: string;
  }) {
    const updatedSelectedCategories = cloneDeep(selectedCategories);
    const categoryGroupIndex = updatedSelectedCategories.findIndex(
      (currentCategoryGroup) => currentCategoryGroup.attributeName === categoryGroup.attributeName
    );
    updatedSelectedCategories[categoryGroupIndex].attributeValues = updatedSelectedCategories[
      categoryGroupIndex
    ].attributeValues.filter((currentCategory) => currentCategory !== category);
    const hasRemainCategories =
      updatedSelectedCategories[categoryGroupIndex].attributeValues.length > 0;
    if (!hasRemainCategories) {
      updatedSelectedCategories.splice(categoryGroupIndex, 1);
    }

    setSelectedCategories(updatedSelectedCategories);
  }

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isSuccess: isSuccessCategories,
    isError: isErrorCategories,
  } = useQuery({
    enabled: isOpen,
    queryKey: [QUERY_KEY_CATEGORIES, selectedTrainedModelId, selectedVendor],
    queryFn: ({ signal }) =>
      getCategories({
        trainedModelId: selectedTrainedModelId,
        vendorId: selectedVendor,
        signal,
      }),
  });

  const {
    isLoading: isLoadingLabels,
    isSuccess: isSuccessLabels,
    isError: isErrorLabels,
    data: labels,
    mutateAsync: mutateAsyncLabels,
    reset: resetLabels,
  } = useMutation({
    mutationKey: [
      QUERY_KEY_LABELS,
      selectedTrainedModelId,
      selectedVendor,
      functionType,
      selectedCategories,
    ],
    mutationFn: getLabels,
    cacheTime: 0,
  });
  const hasSelectedLabels = isSuccessLabels && labels.data.length > 0;

  useEffect(() => {
    if (isOpen) {
      resetLabels();
      const categoriesAux = getValues(formOperationPath)?.categories ?? [];
      const functionTypeAux =
        getValues(formOperationPath)?.functionType ?? FormulaFunctionType.Union;

      setSelectedCategories(categoriesAux);
      setFunctionType(functionTypeAux);

      if (categoriesAux.length > 0) {
        setAddSelectedCategories(categoriesAux);
        updatedSelectedCategories(false, categoriesAux, functionTypeAux);
      }
    }
  }, [isOpen]);

  function renderCategoryList() {
    if (isLoadingCategories) {
      return (
        <ContainerSkeleton data-testid="container-skeleton-categories">
          <SkeletonLoader width="358px" height="56px" variant="body" />
          <SkeletonLoader width="358px" height="56px" variant="body" />
          <SkeletonLoader width="358px" height="56px" variant="body" />
          <SkeletonLoader width="358px" height="56px" variant="body" />
          <SkeletonLoader width="358px" height="56px" variant="body" />
        </ContainerSkeleton>
      );
    }

    if (isErrorCategories) {
      return (
        <ContainerInfo data-testid="container-categories-error">
          <Heading size="H4" alignment="center">
            {formatMessage({
              id: 'ruleCreation.drawer.selectLabels.message.error.default.title',
            })}
          </Heading>
          <Paragraph alignment="center">
            {formatMessage({
              id: 'ruleCreation.drawer.selectLabels.message.error.default.subtitle',
            })}
          </Paragraph>
        </ContainerInfo>
      );
    }

    const isOpenCategoryGroup = !!openCategoryGroup;
    if (isOpenCategoryGroup) {
      const categoriesSelectedByGroup = selectedCategories.find(
        (category) => category.attributeName === openCategoryGroup.attributeName
      )?.attributeValues.length;
      const hasCategoryCounter = categoriesSelectedByGroup > 0;

      return (
        <ContainerCategory data-testid="container-category">
          <ButtonHeaderCategory
            type="button"
            onClick={handleCloseCategoryGroup}
            data-testid="button-header-category"
          >
            <ButtonCloseCategoryGroup>
              <ChevronLeft size="medium" />
            </ButtonCloseCategoryGroup>
            <TitleCategory weight="medium">{openCategoryGroup.attributeName}</TitleCategory>

            <ContainerActionsCategory>
              {hasCategoryCounter && (
                <CounterCategory
                  data-testid="counter-category"
                  counterLabel={categoriesSelectedByGroup}
                />
              )}
            </ContainerActionsCategory>
          </ButtonHeaderCategory>

          <ContainerCategoryList>
            {openCategoryGroup.attributeValues.map((category) => {
              const addedCategoryGroup = selectedCategories.find(
                (currentCategory) =>
                  currentCategory.attributeName === openCategoryGroup.attributeName
              );
              const isChecked =
                !!addedCategoryGroup && addedCategoryGroup.attributeValues.includes(category);

              return (
                <CheckboxCategory
                  data-testid="checkbox-category"
                  key={category}
                  value={category}
                  checked={isChecked}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      addCategory({ categoryGroup: openCategoryGroup, category });
                    } else {
                      removeCategory({ categoryGroup: openCategoryGroup, category });
                    }
                  }}
                  id={category}
                  label={category.toLowerCase()}
                />
              );
            })}
          </ContainerCategoryList>
        </ContainerCategory>
      );
    } else {
      return (
        isSuccessCategories && (
          <ContainerCategoryGroups data-testid="container-category-groups">
            {categories.data.map((categoryGroup) => {
              const categoriesSelectedByGroup = selectedCategories.find(
                (category) => category.attributeName === categoryGroup.attributeName
              )?.attributeValues.length;
              const hasCounter = categoriesSelectedByGroup > 0;

              return (
                <ButtonCategoryGroup
                  key={categoryGroup.attributeName}
                  type="button"
                  onClick={() => handleOpenCategoryGroup(categoryGroup)}
                  data-testid="button-category-group"
                >
                  <ContainerInfoCategoryGroup>
                    <TitleCategoryGroup weight="medium">
                      {categoryGroup.attributeName}
                    </TitleCategoryGroup>

                    <ContainerActionsCategoryGroup>
                      {hasCounter && (
                        <CounterCategoryGroup
                          data-testid="counter-category-group"
                          counterLabel={categoriesSelectedByGroup}
                        />
                      )}

                      <ButtonOpenCategoryGroup>
                        <ChevronRight size="medium" />
                      </ButtonOpenCategoryGroup>
                    </ContainerActionsCategoryGroup>
                  </ContainerInfoCategoryGroup>
                  <Divider />
                </ButtonCategoryGroup>
              );
            })}
          </ContainerCategoryGroups>
        )
      );
    }
  }

  function renderLabelList() {
    if (isLoadingLabels) {
      return (
        <ContainerSkeletonLabels>
          <SkeletonLoader width="280px" height="77px" variant="body" />
          <SkeletonLoader width="280px" height="77px" variant="body" />
          <SkeletonLoader width="280px" height="77px" variant="body" />
          <SkeletonLoader width="280px" height="77px" variant="body" />
          <SkeletonLoader width="280px" height="77px" variant="body" />
          <SkeletonLoader width="280px" height="77px" variant="body" />
        </ContainerSkeletonLabels>
      );
    }

    if (isErrorLabels) {
      return (
        <ContainerInfo data-testid="container-labels-error">
          <Heading size="H4" alignment="center">
            {formatMessage({
              id: 'ruleCreation.drawer.selectLabels.message.error.default.title',
            })}
          </Heading>
          <Paragraph alignment="center">
            {formatMessage({
              id: 'ruleCreation.drawer.selectLabels.message.error.default.subtitle',
            })}
          </Paragraph>
        </ContainerInfo>
      );
    }

    if (hasSelectedLabels) {
      return (
        <ContainerSelectedLabels data-testid="container-selected-labels">
          {labels.data.map((label) => (
            <SelectedLabel key={label.labelName} label={label} />
          ))}
        </ContainerSelectedLabels>
      );
    } else {
      return (
        <ContainerInfo data-testid="container-empty-labels">
          <Heading size="H4" alignment="center">
            {formatMessage({
              id: 'ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.subtitle.noResultsFound.title',
            })}
          </Heading>
          <Paragraph alignment="center">
            {formatMessage({
              id: 'ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.subtitle.noResultsFound.subtitle',
            })}
          </Paragraph>
        </ContainerInfo>
      );
    }
  }

  const shouldRenderDrawer = !!ruleType;
  if (!shouldRenderDrawer) {
    return null;
  }

  return (
    <Drawer.Root
      shouldCloseOnBackdropClick={false}
      data-testid="drawer-select-categories"
      slideDurationMs={drawerSlideDurationMs}
      onCloseDrawer={onCloseDrawer}
      isOpen={isOpen}
      title={formatMessage({
        id: `ruleCreation.drawer.selectLabels.title.${ItemType.Categories}`,
      })}
      headerActions={
        <ContainerHeaderActionButtons>
          <FunctionSelector value={functionType} onChange={onChangeFunctionType}></FunctionSelector>
          <Divider />
        </ContainerHeaderActionButtons>
      }
      footerActions={
        <ContainerFooterActionButtons>
          <Divider />
          <ContainerButtons>
            <ButtonCancel
              type="button"
              data-testid="button-cancel-drawer"
              variant="secondary"
              onClick={onCloseDrawer}
            >
              {formatMessage({
                id: 'ruleCreation.drawer.selectLabels.buttons.cancel',
              })}
            </ButtonCancel>

            <ButtonConfirm
              type="button"
              variant="primary"
              disabled={!hasSelectedLabels || !isOpenNestedDrawerSelectedLabels}
              data-testid="button-confirm-drawer"
              onClick={handleFinishSelection}
            >
              {formatMessage({
                id: 'ruleCreation.drawer.selectLabels.buttons.confirm',
              })}
            </ButtonConfirm>
          </ContainerButtons>
        </ContainerFooterActionButtons>
      }
      nested={
        <Drawer.Nested
          isOpen={isOpenNestedDrawerSelectedLabels}
          title={formatMessage({
            id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.title.${ruleType}`,
          })}
          slideDurationMs={nestedDrawerSlideDurationMs}
          headerActions={
            hasSelectedLabels && (
              <NestedHeaderActions>
                <NestedHeaderSubActions>
                  <ButtonRemoveAll
                    type="button"
                    iconPosition="leading"
                    icon={() => <Trash2 />}
                    onClick={handleRemoveAllLabels}
                    data-testid="button-remove-all-selected-labels"
                  >
                    {formatMessage({
                      id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.button.removeAll`,
                    })}
                  </ButtonRemoveAll>

                  <Paragraph selectable="false" colortype="secondary">
                    {formatMessage(
                      {
                        id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.subtitle.quantity.${ruleType}`,
                      },
                      {
                        quantity: labels.data.length,
                      }
                    )}
                  </Paragraph>
                </NestedHeaderSubActions>
                {showOverwriteCategories && (
                  <ConfirmEdition>
                    <Heading size="H4">
                      {formatMessage({
                        id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.confirmCategories.title`,
                      })}
                    </Heading>
                    <ConfirmEditionSubTitle>
                      <Paragraph size="small">
                        {formatMessage({
                          id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.confirmCategories.subtitle`,
                        })}
                      </Paragraph>
                    </ConfirmEditionSubTitle>

                    <ConfirmEditionActions>
                      <ButtonCancelEdition
                        type="button"
                        variant="secondary"
                        disabled={!hasSelectedLabels || !isOpenNestedDrawerSelectedLabels}
                        data-testid="button-cancel-edition-drawer"
                        onClick={handleCancelPreAddCategories}
                      >
                        {formatMessage({
                          id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.confirmCategories.button.cancel`,
                        })}
                      </ButtonCancelEdition>
                      <ButtonConfirmEdition
                        type="button"
                        variant="destructive"
                        data-testid="button-confirm-edition-drawer"
                        onClick={handleAddCategories}
                      >
                        {formatMessage({
                          id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.confirmCategories.button.confirm`,
                        })}
                      </ButtonConfirmEdition>
                    </ConfirmEditionActions>
                  </ConfirmEdition>
                )}
              </NestedHeaderActions>
            )
          }
        >
          {renderLabelList()}
        </Drawer.Nested>
      }
    >
      {renderCategoryList()}

      <ContainerSelectionActions>
        <ButtonClearSelection
          type="button"
          disabled={isDisabledCategoryActionButtons || isLoadingLabels}
          onClick={handleClearCategorySelection}
          data-testid="button-clear-selection"
        >
          {formatMessage({
            id: 'ruleCreation.drawer.selectLabels.clearSelection',
          })}
        </ButtonClearSelection>

        <ButtonAddSelection
          type="button"
          variant="primary"
          onClick={handlePreAddCategories}
          data-testid="button-add-categories"
          disabled={isDisabledCategoryActionButtons}
          isLoading={isLoadingLabels}
        >
          {formatMessage({
            id: `ruleCreation.drawer.selectLabels.buttons.addItems.${ItemType.Categories}`,
          })}
        </ButtonAddSelection>
      </ContainerSelectionActions>
    </Drawer.Root>
  );
};
