import * as yup from 'yup';
import { FormFieldRule, FormFieldRuleOperation } from '../consts/formField';
import { FormulaMethod } from '../consts/formulaOperation';
import { ItemType } from '../consts/rule';

export const ruleSchema = yup
  .object()
  .shape({
    [FormFieldRule.Name]: yup.string().required(),
    [FormFieldRule.Type]: yup.string().required(),
    [FormFieldRule.RecognitionModelId]: yup.string().required(),
    [FormFieldRule.Operations]: yup.array().of(
      yup.object().shape(
        {
          [FormFieldRuleOperation.Method]: yup.string().required(),
          [FormFieldRuleOperation.Operator]: yup.string().required(),
          [FormFieldRuleOperation.Condition]: yup.string().test({
            name: 'condition',
            message: 'Condition is required',
            test: (value, context) => {
              const isValid =
                value != undefined ||
                context.path ===
                  `${FormFieldRule.Operations}[0].${FormFieldRuleOperation.Condition}`;
              return isValid;
            },
          }),
          [FormFieldRuleOperation.Value]: yup
            .number()
            .required()
            .positive()
            .min(0)
            .integer()
            .when(FormFieldRuleOperation.Method, {
              is: FormulaMethod.OperationShare,
              then: (schema) => schema.max(100),
            }),
          [FormFieldRuleOperation.ItemType]: yup.string().required(),
          [FormFieldRuleOperation.Items]: yup
            .array()
            .of(
              yup.object().shape({
                labelId: yup.string().notRequired(),
                labelName: yup.string().required(),
                quantity: yup.number().required().positive().min(0).integer(),
              })
            )
            .when(FormFieldRuleOperation.ItemType, {
              is: (value) =>
                value === undefined || value === ItemType.Poster || value === ItemType.Sku,
              then: (schema) => schema.required().min(1),
            }),
          [FormFieldRuleOperation.Categories]: yup
            .array()
            .of(
              yup.object().shape({
                attributeId: yup.string().notRequired(),
                attributeName: yup.string().required(),
                attributeValues: yup.array().of(yup.string().required()).required(),
              })
            )
            .when(FormFieldRuleOperation.ItemType, {
              is: (value) => value === undefined || value === ItemType.Categories,
              then: (schema) => schema.required().min(1),
            }),
        },
        [
          [FormFieldRuleOperation.ItemType, FormFieldRuleOperation.Items],
          [FormFieldRuleOperation.ItemType, FormFieldRuleOperation.Categories],
        ]
      )
    ),
  })
  .required();
