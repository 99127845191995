import { Button, Heading, Paragraph, SearchField, TextButton } from '@hexa-ui/components';
import styled from 'styled-components';
import { CustomScrollbar } from '../../styles/GlobalStyles';

export const ContainerFooterActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const ButtonCancel = styled(Button)``;

export const ButtonConfirm = styled(Button)``;

export const ButtonClearSelection = styled(TextButton)`
  color: ${(theme) => theme.theme.colors.positive.color_06};
`;

export const ButtonAddSelection = styled(Button)``;

export const ContainerSelectionActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

export const NestedHeaderActions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  white-space: nowrap;
`;

export const ButtonRemoveAll = styled(TextButton)`
  color: ${(theme) => theme.theme.colors.positive.color_06};
`;

export const ContainerSelectedLabels = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 0;
  padding-right: 32px;
  overflow: auto;

  ${CustomScrollbar}
`;

export const InputSearchField = styled(SearchField.Root)`
  min-width: 140px;
`;

export const InputSearchFieldContentSection = styled.div`
  margin-bottom: 32px;
  margin-top: 32px;
`;

export const ContainerNoResultsSearch = styled.div`
  width: 100%;
`;

export const HeaderNoResultsSearch = styled(Heading)`
  text-align: center;
  margin-top: 8rem;
  margin-bottom: 10px;
`;

export const TextNoResultsSearch = styled(Paragraph)`
  text-align: center;
  margin-bottom: 8rem;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 100px 46px;
`;
