import { Divider as DividerHexa, Input } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import styled from 'styled-components';
import { defaultTheme } from '../../styles/themes/default-theme';
import { InputValueProps } from './FormulaOperation.d';

export const SelectConditionContainer = styled.div`
  width: 90%;
  display: flex;
  flex-basis: 100%;
  align-items: flex-start;
  padding-left: 16px;
  margin: 32px 0;
`;

export const Divider = styled(DividerHexa)`
  align-self: center;
`;

export const FormulaOperationContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 16px;
  flex-wrap: wrap;
`;

export const FormulaOperatorField = styled.div`
  align-self: self-end;
  min-width: 248px;
`;

export const WrapperFormulaOperatorFieldValue = styled.div`
  min-width: 210px;
  display: flex;
`;

export const ActionsButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 8px 24px;
`;

export const FormulaOperatorCondition = styled.div`
  align-self: self-end;
  padding: 0px 16px 0px 16px;

  > button {
    > span {
      text-align: center;
    }
  }
`;

export const InputLabel = styled.label<InputValueProps>`
  padding-bottom: 1.25rem;
  color: ${(props) =>
    props.hasError ? defaultTheme.colors.alert.color_05 : defaultTheme.colors.neutral.color_07};

  &.disabled {
    color: rgba(0, 0, 0, 0.55);
  }

  position: initial;
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.4rem;
  font-weight: 600;
  padding-bottom: var(--space-1);
`;

export const InputValueContainer = styled.div<InputValueProps>`
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
export const InputValue = styled(Input)<InputValueProps>`
  background: white;
`;

export const WrapperSelectOperatorValues = styled.div`
  display: flex;
  gap: 8px;
`;

export const InputValuePercentageSymbol = styled.span<InputValueProps>`
  display: ${(props) => (props.isPercentage ? '' : 'none')};
  position: absolute;
  top: ${(props) => (props.hasError ? '23px' : '2px')};
  right: 5px;
  padding: 5px;
  pointer-events: none;
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: ${(props) =>
    props.hasError ? defaultTheme.colors.alert.color_05 : defaultTheme.colors.neutral.color_06};
  z-index: 1;
`;

export const IconPlus = styled(Plus)`
  display: flex;
`;

export const WrapperItemPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
`;
