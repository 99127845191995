import { Checkbox, Heading, Paragraph, SearchField } from '@hexa-ui/components';
import styled from 'styled-components';
import { CustomScrollbar } from '../../styles/GlobalStyles';

export const HeaderSelectAll = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerLabelsList = styled.div`
  max-height: 350px;
  overflow-y: auto;

  ${CustomScrollbar}
`;

export const LabelItemCheckboxComponent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PosterTitle = styled(Heading)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const SkuTitle = styled(Heading)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const ContainerLabelTitle = styled.div``;

export const AlignedCheckbox = styled(Checkbox)`
  align-self: baseline;
`;
export const Hr = styled.hr``;
