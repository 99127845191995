import { Card, Radio } from '@hexa-ui/components';
import styled from 'styled-components';
import { LabelProps } from './FormRuleCreation.d';

export const FormRuleCreationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
`;

export const RecognitionModelSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ApplicationSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > label {
    padding: 0;
  }
`;

export const RuleTypeSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RuleTypeWrapper = styled.div`
  display: flex;
  gap: 23px;
`;

export const RuleTypeSkeletonWrapper = styled.div`
  display: flex;
  gap: 23px;
`;

export const InputLabel = styled.label<LabelProps>`
  padding-bottom: 1.25rem;
  color: ${(props) => (props.hasError ? props.theme.colors.alert.color_05 : 'inherit')};

  position: initial;
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
  padding-bottom: var(--space-1);
`;

export const ErrorLabel = styled.div`
  color: ${(theme) => theme.theme.colors.alert.color_05};
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
  gap: 5px;

  > svg {
    width: 12px;
    height: 12px;
  }
`;

export const CardRadio = styled(Card)`
  padding: 0 12px;
  display: flex;
`;

export const RadioRoot = styled(Radio.Root)`
  display: flex;
  gap: 12px;
`;

export const SubTitleLabel = styled.span`
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding-bottom: 0.25rem;
`;

export const AuditContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-top: -23px;
`;
