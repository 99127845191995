import { DatePicker, Input, SkeletonLoader } from '@hexa-ui/components';
import { AlertOctagon, MixAndMatch, Rules } from '@hexa-ui/icons';
import { useQuery } from '@tanstack/react-query';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getTrainedModels } from '../../api/services/trainedModels/TrainedModels';
import { AllRecognitionModelsResponse } from '../../api/services/trainedModels/interface';
import { dateLanguage } from '../../consts/dateLocale';
import { FormFieldRule } from '../../consts/formField';
import { QUERY_KEY_TRAINED_MODELS } from '../../consts/query';
import { RuleType } from '../../consts/rule';
import { useUserInfo } from '../../hooks/useUserInfo';
import { FormulaSection } from '../FormulaSection/FormulaSection';
import { RecognitionModelSelect } from '../RecognitionModelSelect/RecognitionModelSelect';
import { RecognitionModel } from '../RecognitionModelSelect/RecognitionModelSelect.d';
import { RuleTypeButton } from '../RuleTypeButton/RuleTypeButton';
import { RuleTypeButton as IRuleTypeButton } from '../RuleTypeButton/RuleTypeButton.d';
import { FormRuleCreationProps } from './FormRuleCreation.d';
import {
  AuditContainer,
  ErrorLabel,
  FormRuleCreationWrapper,
  InputLabel,
  RecognitionModelSection,
  RuleTypeSection,
  RuleTypeSkeletonWrapper,
  RuleTypeWrapper,
} from './FormRuleCreation.styles';

const ruleTypeButtonIcons = {
  [RuleType.Advertisement]: <Rules size="xlarge" />,
  [RuleType.Sku]: <MixAndMatch size="xlarge" />,
};

const getRuleTypeButtons = (recognitionModels: RecognitionModel[]): IRuleTypeButton[] => {
  if (!recognitionModels) return;

  const uniqueModelTypes = recognitionModels
    .map((model: RecognitionModel) => model.modelType)
    .filter((modelType, index, array) => array.indexOf(modelType) === index);

  const updatedRuleTypes = Object.values(RuleType)
    .filter((ruleTypeId) => ruleTypeId !== RuleType.Poster)
    .map((ruleTypeId) => {
      return { ruleTypeId, disabled: !uniqueModelTypes.includes(ruleTypeId) };
    });

  return updatedRuleTypes.map((ruleType) => {
    return {
      ...ruleType,
      Icon: ruleTypeButtonIcons[ruleType.ruleTypeId],
    };
  });
};

export const FormRuleCreation = ({
  isUpdateDisable = false,
}: FormRuleCreationProps): JSX.Element => {
  const {
    setValue,
    trigger,
    clearErrors,
    register,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { formatMessage } = useIntl();
  const userInfo = useUserInfo();
  const { preferredLanguage } = usePreferredLanguageV2();
  const vendorId = userInfo.selectedVendor;

  const hasRuleTypeError = Boolean(errors[FormFieldRule.Type]);

  const [ruleTypeSelected, setRuleTypeSelected] = useState(getValues(FormFieldRule.Type));

  const [filteredRecognitionModels, setFilteredRecognitionModels] = useState<RecognitionModel[]>(
    []
  );

  const ruleType = getValues(FormFieldRule.Type);

  const { data: trainedModelsFetch } = useQuery<
    AxiosResponse<AllRecognitionModelsResponse>,
    AxiosError
  >([QUERY_KEY_TRAINED_MODELS, vendorId], ({ signal }) => getTrainedModels({ signal, vendorId }), {
    staleTime: Infinity,
    cacheTime: 0,

    onSuccess(data) {
      setFilteredRecognitionModels(data.data.content);
      if (data.data.content.length === 1) {
        setValue(FormFieldRule.RecognitionModelId, data.data.content[0].trainedModelId);
      }
    },
  });

  const trainedModelsContent = trainedModelsFetch?.data?.content;
  const ruleTypeButtons = getRuleTypeButtons(trainedModelsContent);

  const handleRuleNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const regex = /[^a-zA-Z0-9\s&$\-_.]+/g;
      const filteredValue = value.replace(regex, '');

      setValue(FormFieldRule.Name, filteredValue);
      trigger(FormFieldRule.Name);
    },
    [setValue, trigger]
  );

  const recognitionModelByType = filteredRecognitionModels.filter(
    (trainedModel) => trainedModel.modelType === ruleType
  );

  const getTrainedModelId = () => {
    if (!getValues(FormFieldRule.RecognitionModelId)) {
      if (recognitionModelByType.length === 1) {
        setValue(FormFieldRule.RecognitionModelId, recognitionModelByType[0]?.trainedModelId);
        clearErrors(FormFieldRule.RecognitionModelId);
        return recognitionModelByType[0]?.trainedModelId;
      }
    }
    return getValues(FormFieldRule.RecognitionModelId);
  };

  return (
    <FormRuleCreationWrapper data-testid="form-rule-creation-wrapper">
      <Input
        {...register(FormFieldRule.Name, { required: true })}
        label={formatMessage({ id: 'ruleCreation.form.ruleName.label' })}
        hasError={Boolean(errors[FormFieldRule.Name])}
        errorText={formatMessage({ id: 'ruleCreation.form.ruleName.error' })}
        characterCounter={true}
        maxLength={80}
        placeholder={formatMessage({ id: 'ruleCreation.form.ruleName.placeholder' })}
        width="100%"
        data-testid="input-rule-name"
        onChange={handleRuleNameChange}
        value={getValues(FormFieldRule.Name)}
      />
      {isUpdateDisable && (
        <AuditContainer>
          <Input
            {...register(FormFieldRule.UpdatedBy)}
            label={formatMessage({ id: 'ruleCreation.form.updatedBy.label' })}
            width="25%"
            disabled={true}
            data-testid="input-updated-by"
            value={getValues(FormFieldRule.UpdatedBy)}
          />

          <DatePicker
            {...register(FormFieldRule.UpdatedAt)}
            label={formatMessage({ id: 'ruleCreation.form.updatedOn.label' })}
            inputDisabled={true}
            onChange={() => {}}
            value={[new Date(getValues(FormFieldRule.UpdatedAt) ?? Date.now())]}
            mode="single"
            width="25%"
            customLocale={dateLanguage[preferredLanguage]}
            data-testid="input-updated-on"
          />
        </AuditContainer>
      )}
      <RuleTypeSection>
        <InputLabel hasError={hasRuleTypeError}>
          {formatMessage({ id: 'ruleCreation.form.ruleType.label' })}
        </InputLabel>
        {hasRuleTypeError && (
          <ErrorLabel data-testid="error-label">
            <AlertOctagon />
            {formatMessage({ id: 'ruleCreation.form.ruleType.error' })}
          </ErrorLabel>
        )}
        <RuleTypeWrapper>
          {ruleTypeButtons ? (
            ruleTypeButtons.map((ruleTypeButton) => {
              return (
                <RuleTypeButton
                  disabled={ruleTypeButton.disabled || isUpdateDisable}
                  key={ruleTypeButton.ruleTypeId}
                  ruleTypeId={ruleTypeButton.ruleTypeId}
                  buttonIcon={ruleTypeButton.Icon}
                  labelText={formatMessage({
                    id: `ruleCreation.form.ruleType.value.${ruleTypeButton.ruleTypeId}`,
                  })}
                  ruleTypeSelected={getValues(FormFieldRule.Type)}
                  setRuleTypeSelected={setRuleTypeSelected}
                  ruleTypeButtons={ruleTypeButtons}
                ></RuleTypeButton>
              );
            })
          ) : (
            <RuleTypeSkeletonWrapper data-testid="rule-type-skeleton-wrapper">
              <SkeletonLoader width="135px" height="155px" variant="body" />
              <SkeletonLoader width="135px" height="155px" variant="body" />
            </RuleTypeSkeletonWrapper>
          )}
        </RuleTypeWrapper>
      </RuleTypeSection>
      <RecognitionModelSection>
        <RecognitionModelSelect
          disabled={Boolean(!ruleType) || isUpdateDisable}
          trainedModelId={getTrainedModelId()}
          recognitionModels={recognitionModelByType}
        ></RecognitionModelSelect>
      </RecognitionModelSection>
      <FormulaSection />
    </FormRuleCreationWrapper>
  );
};
