import { NestedProps } from './Nested.d';
import {
  HeaderActions,
  HeaderDivider,
  HeaderTitle,
  NestedDrawerContainer,
  NestedDrawerContent,
  NestedDrawerHeader,
} from './Nested.styles';

const Nested = ({
  title = 'Nested Drawer Component',
  isOpen,
  children,
  headerActions,
  widthPx = 408,
  rootWidthPx = 432,
  slideDurationMs = 1000,
  ...rest
}: NestedProps): JSX.Element => {
  return (
    <NestedDrawerContainer
      isOpen={isOpen}
      data-testid="nested-drawer"
      widthPx={widthPx}
      rootWidthPx={rootWidthPx}
      slideDurationMs={slideDurationMs}
      {...rest}
    >
      <NestedDrawerHeader>
        <HeaderTitle size="H3" data-testid="nested-drawer-title">
          {title}
        </HeaderTitle>

        {headerActions && <HeaderActions>{headerActions}</HeaderActions>}

        <HeaderDivider orientation="horizontal" />
      </NestedDrawerHeader>

      <NestedDrawerContent>{children}</NestedDrawerContent>
    </NestedDrawerContainer>
  );
};

export default Nested;
