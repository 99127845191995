import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormFieldRule } from '../../consts/formField';
import { ModalCustom } from '../Modal';
import { ModalProps } from '../Modal/Modal.d';
import { RuleTypeButtonProps } from './RuleTypeButton.d';
import { Button, IconWrapper, LabelText, RuleTypeButtonContainer } from './RuleTypeButton.styles';

export const RuleTypeButton = ({
  buttonIcon,
  ruleTypeId,
  labelText,
  ruleTypeSelected,
  ruleTypeButtons,
  disabled,
  setRuleTypeSelected,
}: RuleTypeButtonProps): JSX.Element => {
  const {
    setValue,
    clearErrors,
    resetField,
    watch,
    formState: { errors },
  } = useFormContext();
  const { formatMessage } = useIntl();
  const recognitionModelSelect = watch(FormFieldRule.RecognitionModelId);
  const hasRuleTypeError = Boolean(errors[FormFieldRule.Type]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<ModalProps>({
    buttonSecondaryLabel: '',
    buttonPrimaryLabel: '',
    description: '',
    title: '',
    isOpen: false,
  });
  const isActive = ruleTypeSelected === ruleTypeId;

  function handleChangeRuleType() {
    setRuleTypeSelected(ruleTypeId);
    setValue(FormFieldRule.Type, ruleTypeId);
    resetField(FormFieldRule.Operations);
    setValue(FormFieldRule.RecognitionModelId, null);
    clearErrors(FormFieldRule.Type);
    setIsModalOpen(false);
  }

  function handleOnClickRuleType() {
    if (disabled) return;
    if (recognitionModelSelect && !isActive) return handleOpenModal();
    handleChangeRuleType();
  }

  function handleOpenModal() {
    setModalInfo({
      buttonSecondaryLabel: formatMessage({
        id: `ruleCreation.modal.${FormFieldRule.Type}.buttonSecondaryLabel`,
      }),
      buttonPrimaryLabel: formatMessage({
        id: `ruleCreation.modal.${FormFieldRule.Type}.buttonPrimaryLabel`,
      }),
      description: formatMessage({
        id: `ruleCreation.modal.${FormFieldRule.Type}.description`,
      }),
      title: formatMessage({ id: `ruleCreation.modal.${FormFieldRule.Type}.title` }),
      isOpen: false,
    });
    setIsModalOpen(true);
  }

  useEffect(() => {
    const singleRuleTypeActive = ruleTypeButtons.filter((ruleType) => !ruleType.disabled);
    const singleRuleTypeActiveId =
      singleRuleTypeActive[singleRuleTypeActive.length - 1]?.ruleTypeId;

    if (singleRuleTypeActive.length === 1) {
      setRuleTypeSelected(singleRuleTypeActiveId);
      setValue(FormFieldRule.Type, singleRuleTypeActiveId);
    }
  }, []);

  return (
    <RuleTypeButtonContainer data-testid="rule-type-button">
      <Button
        data-testid={`rule-type-button-${ruleTypeId}`.toLowerCase()}
        disabled={disabled}
        hasError={hasRuleTypeError}
        isActive={isActive}
        onClick={() => handleOnClickRuleType()}
      >
        <IconWrapper disabled={disabled} ruleTypeId={ruleTypeId}>
          {buttonIcon}
        </IconWrapper>
        <LabelText>{labelText}</LabelText>
      </Button>
      <ModalCustom
        buttonPrimaryLabel={modalInfo.buttonPrimaryLabel}
        buttonSecondaryLabel={modalInfo.buttonSecondaryLabel}
        description={modalInfo.description}
        title={modalInfo.title}
        handleConfirm={() => handleChangeRuleType()}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      ></ModalCustom>
    </RuleTypeButtonContainer>
  );
};
