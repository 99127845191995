import { IconButton } from '@hexa-ui/components';
import styled from 'styled-components';
import { DrawerContainerProps, DrawerWrapperProps } from './Root.d';

export const DrawerWrapper = styled.div<DrawerWrapperProps>`
  position: fixed;
  top: 1%;
  bottom: 1%;
  right: ${(props) => (props.isOpen ? '0px' : '-100%')};
  z-index: 998;
  height: 98%;
  pointer-events: ${(props) => (props.isOpen ? 'all' : 'none')};
  transition: right ${(props) => `${props.slideDurationMs}ms`};
`;

export const DrawerContainer = styled.div<DrawerContainerProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 998;
  width: ${(props) => `${props.widthPx}px`};
  height: 100%;
  padding: 32px;
  background-color: rgb(255, 255, 255);
  border-radius: 16px 0px 0px 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 8px;
  border: 1px solid #dbdada;
`;

export const DrawerHeader = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ButtonCloseDrawer = styled(IconButton)``;

export const DrawerHeaderActions = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const DrawerFooterActions = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 0;
`;
