import { Divider, Heading, Paragraph } from '@hexa-ui/components';
import { Trash2 } from '@hexa-ui/icons';
import { useMutation } from '@tanstack/react-query';
import { FormEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getLabels } from '../../api/services/labelList/LabelList';
import { FormFieldRule } from '../../consts/formField';
import { QUERY_KEY_LABELS } from '../../consts/query';
import { ItemType } from '../../consts/rule';
import { useUserInfo } from '../../hooks/useUserInfo';
import { Label, RuleOperation } from '../../models/rule';
import Drawer from '../Drawer';
import { LabelListSelector } from '../LabelListSelector';
import { SelectedLabel } from '../SelectedLabel';
import { SkeletonLoaderSku } from '../SkeletonLoaderSku';
import { DrawerSelectLabelsProps } from './DrawerSelectLabels.d';
import {
  ButtonAddSelection,
  ButtonCancel,
  ButtonClearSelection,
  ButtonConfirm,
  ButtonRemoveAll,
  ContainerButtons,
  ContainerFooterActionButtons,
  ContainerInfo,
  ContainerNoResultsSearch,
  ContainerSelectedLabels,
  ContainerSelectionActions,
  HeaderNoResultsSearch,
  InputSearchField,
  InputSearchFieldContentSection,
  NestedHeaderActions,
  TextNoResultsSearch,
} from './DrawerSelectLabels.styles';

export const DrawerSelectLabels = ({
  onClose,
  isOpen,
  itemType,
  selectedTrainedModelId,
  ruleType,
  operationIndex,
}: DrawerSelectLabelsProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const userInfo = useUserInfo();
  const { selectedVendor } = userInfo;
  const [selectedItemType, setSelectedItemType] = useState<ItemType>(null);
  const formOperationPath = `${FormFieldRule.Operations}.${operationIndex}`;
  const [checkedAllLabels, setCheckedAllLabels] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const { setValue, clearErrors, getValues } = useFormContext();

  const [checkedLabels, setCheckedLabels] = useState<string[]>(
    getValues(formOperationPath)?.items?.map((t) => t.labelName) ?? []
  );
  const [addedLabels, setAddedLabels] = useState<Label[]>(
    getValues(formOperationPath)?.items ?? []
  );

  const addedLabelsIds = addedLabels.map((label) => label.labelName);
  const isSelectedItemTypeSku = selectedItemType === ItemType.Sku;
  const isSelectedItemTypePoster = selectedItemType === ItemType.Poster;
  const drawerSlideDurationMs = 1000;
  const nestedDrawerSlideDurationMs = 1000;
  const hasAddedLabels = addedLabels.length > 0;
  const hasCheckedLabels = checkedLabels.length > 0;
  const isOpenNestedDrawerAddedLabels = hasAddedLabels && hasCheckedLabels;
  const isDisabledClearSelectionButton = checkedLabels.length === 0;

  const {
    data: labelsResponse,
    isLoading: isLoadingLabels,
    isError: isErrorLabels,
    isSuccess: isSuccessLabels,
    mutate: mutateLabels,
  } = useMutation({
    mutationKey: [QUERY_KEY_LABELS, selectedTrainedModelId, selectedVendor],
    mutationFn: getLabels,
  });

  const sanitizedSearchTerm = searchValue.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
  const filteredLabels = labelsResponse?.data.filter((label) => {
    const sanitizedLabel = label?.labelName.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    return sanitizedLabel?.includes(sanitizedSearchTerm);
  });
  const searchResults = filteredLabels ? filteredLabels : null;

  function handleSearchChange(event: FormEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    setSearchValue(value);
  }

  function handleClearSearch() {
    setSearchValue('');
  }

  function onCloseDrawer() {
    !!onClose && onClose();

    setTimeout(() => {
      handleClearSearch();
      handleClearAddedLabels();
      handleClearAllLabels();
    }, drawerSlideDurationMs);
  }

  function handleAddLabel() {
    const addedLabelsInfo = labelsResponse.data
      .filter((label) => checkedLabels.includes(label.labelName))
      .map((label) => ({
        labelId: label.labelId,
        labelName: label.labelName,
        quantity: label.quantity,
        commercialName: label.commercialName,
      }));

    setAddedLabels(addedLabelsInfo);
  }

  function handleRemoveAllLabels() {
    handleClearAllLabels();
  }

  function handleRemoveEachLabel(item: Label) {
    setAddedLabels((addedItems) =>
      addedItems.filter((addedItem) => addedItem.labelName !== item.labelName)
    );

    setCheckedLabels((checkedLabels) =>
      checkedLabels.filter((checkedLabel) => checkedLabel !== item.labelName)
    );

    setCheckedAllLabels(false);
  }

  function handleClearAddedLabels() {
    setAddedLabels([]);
  }

  function handleClearAllLabels() {
    setCheckedLabels([]);
    setCheckedAllLabels(false);

    setTimeout(() => {
      handleClearAddedLabels();
    }, 500);
  }

  function handleUncheckAllLabels() {
    const allChecked = checkedLabels.length === labelsResponse.data.length;
    const allSelected = addedLabels.length === labelsResponse.data.length;

    if (allChecked != allSelected) {
      setCheckedAllLabels(false);
    }
    const newCheckedItems = [...checkedLabels];

    const indexesToRemove = checkedLabels
      .map((item, index) => (addedLabelsIds.includes(item) ? -1 : index))
      .filter((index) => index !== -1);

    indexesToRemove.reverse().forEach((index) => {
      newCheckedItems.splice(index, 1);
    });

    setCheckedLabels(newCheckedItems);
  }

  function handleFinishSelection() {
    const formOperationPath = `${FormFieldRule.Operations}.${operationIndex}`;
    const formPathItems = `${formOperationPath}.items`;
    const formPathCategories = `${formOperationPath}.categories`;
    const updatedOperation: RuleOperation = getValues(formOperationPath);

    updatedOperation.items = addedLabels;
    updatedOperation.count = addedLabels.length;
    delete updatedOperation.categories;
    delete updatedOperation.functionType;

    setValue(formOperationPath, updatedOperation);
    clearErrors(formPathItems);
    clearErrors(formPathCategories);

    onCloseDrawer();
  }

  useEffect(() => {
    if (!!selectedItemType && !!selectedTrainedModelId) {
      mutateLabels({ trainedModelId: selectedTrainedModelId, vendorId: selectedVendor });
    }
  }, [selectedTrainedModelId, selectedItemType]);

  useEffect(() => {
    const hasItemType = !!itemType;
    if (hasItemType) {
      setSelectedItemType(itemType);
    } else {
      setTimeout(() => {
        setSelectedItemType(null);
      }, drawerSlideDurationMs);
    }
  }, [itemType]);

  useEffect(() => {
    if (isOpen) {
      if (!getValues(formOperationPath).categories && isSuccessLabels) {
        setCheckedLabels(getValues(formOperationPath)?.items?.map((t) => t.labelName) ?? []);
        const addedLabelsInfo = labelsResponse.data
          .filter((label) =>
            getValues(formOperationPath)?.items?.some(
              (selectedLabel) => selectedLabel.labelName === label.labelName
            )
          )
          .map((label) => ({
            labelId: label.labelId,
            labelName: label.labelName,
            quantity: label.quantity,
            commercialName: label.commercialName,
          }));
        setAddedLabels(addedLabelsInfo);
      }
    }
  }, [isOpen, isSuccessLabels]);

  const shouldRenderDrawer = !!ruleType && !!selectedItemType;
  if (!shouldRenderDrawer) {
    return null;
  }

  function renderLabelList() {
    if (isLoadingLabels) {
      return <SkeletonLoaderSku />;
    }

    if (isErrorLabels) {
      return (
        <ContainerInfo data-testid="container-labels-error">
          <Heading size="H4" alignment="center">
            {formatMessage({
              id: 'ruleCreation.drawer.selectLabels.message.error.default.title',
            })}
          </Heading>
          <Paragraph alignment="center">
            {formatMessage({
              id: 'ruleCreation.drawer.selectLabels.message.error.default.subtitle',
            })}
          </Paragraph>
        </ContainerInfo>
      );
    }

    if (isSuccessLabels) {
      return (
        <>
          <InputSearchFieldContentSection>
            <InputSearchField
              data-testid="label-menu-search"
              placeholder={formatMessage({
                id: 'ruleCreation.drawer.selectLabels.placeholder.search',
              })}
              value={searchValue}
              size="medium"
              onChange={handleSearchChange}
              onClear={handleClearSearch}
            />
          </InputSearchFieldContentSection>
          {!!searchResults && searchResults.length === 0 ? (
            <ContainerNoResultsSearch>
              <HeaderNoResultsSearch size="H3">
                {formatMessage({ id: 'ruleCreation.drawer.selectLabels.noSearchResults.title' })}
              </HeaderNoResultsSearch>
              <TextNoResultsSearch colortype="secondary" size="small">
                {formatMessage({ id: 'ruleCreation.drawer.selectLabels.noSearchResults.subtitle' })}
              </TextNoResultsSearch>
            </ContainerNoResultsSearch>
          ) : (
            <LabelListSelector
              addedLabelsIds={addedLabelsIds}
              checkedLabels={checkedLabels}
              setCheckedLabels={setCheckedLabels}
              selectedItemType={selectedItemType}
              labels={labelsResponse.data}
              checkedAllLabels={checkedAllLabels}
              setCheckedAllLabels={setCheckedAllLabels}
              uncheckAllLabels={handleUncheckAllLabels}
              searchResults={searchResults}
            />
          )}
        </>
      );
    }
  }

  return (
    <Drawer.Root
      shouldCloseOnBackdropClick={false}
      slideDurationMs={drawerSlideDurationMs}
      data-testid="drawer-select-labels"
      isOpen={isOpen}
      onCloseDrawer={onCloseDrawer}
      title={formatMessage({
        id: `ruleCreation.drawer.selectLabels.title.${selectedItemType}`,
      })}
      footerActions={
        <ContainerFooterActionButtons>
          <Divider />
          <ContainerButtons>
            <ButtonCancel
              type="button"
              data-testid="button-cancel-drawer"
              variant="secondary"
              onClick={onCloseDrawer}
            >
              {formatMessage({
                id: 'ruleCreation.drawer.selectLabels.buttons.cancel',
              })}
            </ButtonCancel>

            <ButtonConfirm
              type="button"
              variant="primary"
              disabled={!(hasAddedLabels && hasCheckedLabels)}
              data-testid="button-confirm-drawer"
              onClick={handleFinishSelection}
            >
              {formatMessage({
                id: 'ruleCreation.drawer.selectLabels.buttons.confirm',
              })}
            </ButtonConfirm>
          </ContainerButtons>
        </ContainerFooterActionButtons>
      }
      nested={
        <Drawer.Nested
          slideDurationMs={nestedDrawerSlideDurationMs}
          isOpen={isOpenNestedDrawerAddedLabels}
          title={formatMessage({
            id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.title.${ruleType}`,
          })}
          headerActions={
            <NestedHeaderActions>
              <ButtonRemoveAll
                type="button"
                iconPosition="leading"
                icon={() => <Trash2 />}
                onClick={handleRemoveAllLabels}
                data-testid="button-remove-all-selected-labels"
              >
                {formatMessage({
                  id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.button.removeAll`,
                })}
              </ButtonRemoveAll>

              <Paragraph selectable="false" colortype="secondary">
                {formatMessage(
                  {
                    id: `ruleCreation.drawer.selectLabels.nestedDrawer.selectedLabels.subtitle.quantity.${ruleType}`,
                  },
                  {
                    quantity: addedLabels.length,
                  }
                )}
              </Paragraph>
            </NestedHeaderActions>
          }
        >
          <ContainerSelectedLabels>
            {addedLabels.map((label) => (
              <SelectedLabel
                key={label.labelId}
                label={label}
                isRemovable={isSelectedItemTypeSku || isSelectedItemTypePoster}
                onRemove={handleRemoveEachLabel}
                hideDescription={isSelectedItemTypePoster}
                selectedItemType={selectedItemType}
              />
            ))}
          </ContainerSelectedLabels>
        </Drawer.Nested>
      }
    >
      {renderLabelList()}
      <ContainerSelectionActions>
        <ButtonClearSelection
          data-testid="clear-all-checkeds"
          type="button"
          onClick={handleUncheckAllLabels}
          disabled={isDisabledClearSelectionButton || isLoadingLabels}
        >
          {formatMessage({
            id: 'ruleCreation.drawer.selectLabels.clearSelection',
          })}
        </ButtonClearSelection>

        <ButtonAddSelection
          type="button"
          variant="primary"
          disabled={checkedLabels.length === 0}
          onClick={handleAddLabel}
          data-testid="button-add-label"
        >
          {formatMessage({
            id: `ruleCreation.drawer.selectLabels.buttons.addItems.${selectedItemType}`,
          })}
        </ButtonAddSelection>
      </ContainerSelectionActions>
    </Drawer.Root>
  );
};
