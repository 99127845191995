import styled from 'styled-components';

interface StyledContainerProps {
  hasAppHeader: boolean;
  hasBreadcrumb: boolean;
}

export const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  padding-left: 6.5rem;
`;
