import { Select } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormFieldRule } from '../../consts/formField';
import { ModalCustom } from '../Modal';
import { ModalProps } from '../Modal/Modal.d';
import { RecognitionModel, RecognitionModelSelectProps } from './RecognitionModelSelect.d';
import { ContainerSelect, Hr, InfoLabel, InfoOption } from './RecognitionModelSelect.styles';

export const RecognitionModelSelect = ({
  recognitionModels,
  trainedModelId,
  disabled,
}: RecognitionModelSelectProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    setValue,
    clearErrors,
    resetField,
    formState: { errors },
  } = useFormContext();

  const [recognitionModelSelected, setRecognitionModelSelected] = useState(null);
  const [recognitionModelPreSelected, setRecognitionModelPreSelected] = useState(null);

  const hasRecognitionModelError = Boolean(errors[FormFieldRule.RecognitionModelId]);
  const selectButton = document.getElementById('select-recognition-model');
  const selectPlaceholder = formatMessage({
    id: 'ruleCreation.form.recognitionModel.select.placeholder',
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalInfo, setModalInfo] = useState<ModalProps>({
    buttonSecondaryLabel: '',
    buttonPrimaryLabel: '',
    description: '',
    title: '',
    isOpen: false,
  });

  function handleOpenModal() {
    setModalInfo({
      buttonSecondaryLabel: formatMessage({
        id: `ruleCreation.modal.${FormFieldRule.RecognitionModelId}.buttonSecondaryLabel`,
      }),
      buttonPrimaryLabel: formatMessage({
        id: `ruleCreation.modal.${FormFieldRule.RecognitionModelId}.buttonPrimaryLabel`,
      }),
      description: formatMessage({
        id: `ruleCreation.modal.${FormFieldRule.RecognitionModelId}.description`,
      }),
      title: formatMessage({ id: `ruleCreation.modal.${FormFieldRule.RecognitionModelId}.title` }),
      isOpen: false,
    });
    setIsModalOpen(true);
  }

  function handleOnChangeRecognitionModelConfirmModal(recognitionModelSelectValue: string) {
    const recognitionModelValue = recognitionModelPreSelected || recognitionModelSelectValue;

    setRecognitionModelSelected(recognitionModelValue);
    setValue(FormFieldRule.RecognitionModelId, recognitionModelValue);
    clearErrors(FormFieldRule.RecognitionModelId);
    resetField(FormFieldRule.Operations);
    setIsModalOpen(false);

    document.getElementById(
      'select-recognition-model'
    ).firstChild.firstChild.childNodes[0].textContent = '';
  }
  function handleOnSelectChange(recognitionModelSelectValue: string) {
    setRecognitionModelPreSelected(recognitionModelSelectValue);

    if (recognitionModelSelected) handleOpenModal();
    else handleOnChangeRecognitionModelConfirmModal(recognitionModelSelectValue);
  }

  useEffect(() => {
    if (trainedModelId && selectButton) {
      setRecognitionModelSelected(trainedModelId);
      setRecognitionModelPreSelected(null);

      if (selectButton && !recognitionModelSelected) {
        selectButton.firstChild.firstChild.textContent = '';
      }
    } else {
      setRecognitionModelSelected(null);
      setRecognitionModelPreSelected(null);
      if (selectButton && !recognitionModelSelected) {
        selectButton.firstChild.firstChild.textContent = selectPlaceholder;
      }
    }
  }, [trainedModelId, selectButton, recognitionModelSelected]);

  return (
    <ContainerSelect data-testid="container-recognition-model-select">
      <Select.Root
        name="recognition-model"
        id="select-recognition-model"
        data-testid="select-recognition-model"
        disabled={disabled}
        value={recognitionModelSelected}
        placeholder={selectPlaceholder}
        size="large"
        onChange={(value) => (value ? handleOnSelectChange(value) : void 0)}
        error={
          hasRecognitionModelError
            ? formatMessage({
                id: 'ruleCreation.form.recognitionModel.error',
              })
            : ''
        }
      >
        {recognitionModels ? (
          recognitionModels.map((model: RecognitionModel) => {
            return (
              <Select.Option
                data-testid="option-recognition-model"
                key={model.trainedModelId}
                value={model.trainedModelId}
              >
                {model.description}
              </Select.Option>
            );
          })
        ) : (
          <></>
        )}

        {recognitionModelSelected ? (
          <InfoOption disabled={true} value="">
            <InfoLabel data-testid="info-label-option">
              <Hr />
              {formatMessage({ id: 'ruleCreation.form.recognitionModel.select.infoLabel' })}
            </InfoLabel>
          </InfoOption>
        ) : (
          <></>
        )}
      </Select.Root>
      <ModalCustom
        buttonPrimaryLabel={modalInfo.buttonPrimaryLabel}
        buttonSecondaryLabel={modalInfo.buttonSecondaryLabel}
        description={modalInfo.description}
        title={modalInfo.title}
        handleConfirm={() => handleOnChangeRecognitionModelConfirmModal(recognitionModelSelected)}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      ></ModalCustom>
    </ContainerSelect>
  );
};
