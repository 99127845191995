import { Card, TextLink } from '@hexa-ui/components';
import styled from 'styled-components';
import { ScreenBreakpoint } from '../../consts/screen';
import { defaultTheme } from '../../styles/themes/default-theme';
import { AddOperationProps } from './FormulaSection.d';

export const FormulaSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddOperationTextLink = styled(TextLink)<AddOperationProps>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  color: ${({ disabled }) =>
    disabled ? defaultTheme.colors.neutral.shadow_04 : defaultTheme.colors.positive.color_06};
  max-width: 190px;
`;

export const FormulaSectionCard = styled(Card)`
  display: flex;
  padding: 40px 32px 40px;
  gap: 30px;

  @media (max-width: ${ScreenBreakpoint.Mobile}) {
    padding: 24px 16px 24px;
  }
`;

export const TitleLabel = styled.label`
  position: initial;
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
  padding-bottom: var(--space-1);
`;

export const SubTitleLabel = styled.span`
  font-family: Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 0 1.25rem;
`;
