import { enUS, es, ptBR } from 'date-fns/locale';

export const dateLocales = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-419': es,
};

export const dateLanguage = {
  'pt-BR': 'pt',
  'en-US': 'en',
  'es-419': 'es',
};
