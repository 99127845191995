import { IconButton, Input, Paragraph, Select } from '@hexa-ui/components';
import { List, Plus, Trash2 } from '@hexa-ui/icons';
import { useEffect, useState } from 'react';
import { FieldErrors, FieldValues, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormFieldRule, FormFieldRuleOperation } from '../../consts/formField';
import {
  FormulaCondition,
  FormulaMethod,
  FormulaOperator,
  ItemOption,
} from '../../consts/formulaOperation';
import { ItemType, RuleType } from '../../consts/rule';
import { useUserInfo } from '../../hooks/useUserInfo';
import { RuleOperation } from '../../models/rule';
import EqualIcon from './../../assets/icons/equal.svg';
import GreaterIcon from './../../assets/icons/greater.svg';
import GreaterEqualIcon from './../../assets/icons/greater_equal.svg';
import LesserIcon from './../../assets/icons/lesser.svg';
import LesserEqualIcon from './../../assets/icons/lesser_equal.svg';
import { FormulaOperationFields, FormulaOperationProps } from './FormulaOperation.d';
import {
  ActionsButtonsWrapper,
  Divider,
  FormulaOperationContainer,
  FormulaOperatorCondition,
  FormulaOperatorField,
  IconPlus,
  InputLabel,
  InputValue,
  InputValueContainer,
  InputValuePercentageSymbol,
  SelectConditionContainer,
  WrapperFormulaOperatorFieldValue,
  WrapperItemPlaceholder,
  WrapperSelectOperatorValues,
} from './FormulaOperation.styles';

function getFormulaOperationFieldsProps(
  index: number,
  errors: FieldErrors<FieldValues>
): FormulaOperationFields {
  return {
    formPathMethod: `${FormFieldRule.Operations}.${index}.${FormFieldRuleOperation.Method}`,
    formPathOperator: `${FormFieldRule.Operations}.${index}.${FormFieldRuleOperation.Operator}`,
    formPathValue: `${FormFieldRule.Operations}.${index}.${FormFieldRuleOperation.Value}`,
    formPathCondition: `${FormFieldRule.Operations}.${index}.${FormFieldRuleOperation.Condition}`,
    formPathItems: `${FormFieldRule.Operations}.${index}.${FormFieldRuleOperation.Items}`,
    formPathItemType: `${FormFieldRule.Operations}.${index}.${FormFieldRuleOperation.ItemType}`,
    formPathCategories: `${FormFieldRule.Operations}.${index}.${FormFieldRuleOperation.Categories}`,
    errorMethod: errors[FormFieldRule.Operations]?.[index]?.[FormFieldRuleOperation.Method],
    errorOperator: errors[FormFieldRule.Operations]?.[index]?.[FormFieldRuleOperation.Operator],
    errorCondition: errors[FormFieldRule.Operations]?.[index]?.[FormFieldRuleOperation.Condition],
    errorValue: errors[FormFieldRule.Operations]?.[index]?.[FormFieldRuleOperation.Value],
    errorItems: errors[FormFieldRule.Operations]?.[index]?.[FormFieldRuleOperation.Items],
    errorCategories: errors[FormFieldRule.Operations]?.[index]?.[FormFieldRuleOperation.Categories],
  };
}

export const FormulaOperation = ({
  index,
  onDeleteOperation,
  onSelectItem,
  isDrawerClosed,
  disabled = false,
}: FormulaOperationProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    register,
    getValues,
    setValue,
    clearErrors,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();
  const {
    formPathValue,
    formPathCondition,
    formPathMethod,
    formPathOperator,
    formPathItemType,
    errorValue,
    errorCondition,
    errorItems,
    errorCategories,
    errorMethod,
    errorOperator,
  } = getFormulaOperationFieldsProps(index, errors);
  const userInfo = useUserInfo();
  const [isPercentage, setIsPercentage] = useState(
    watch(formPathMethod) === FormulaMethod.OperationShare
  );
  const [isSelectingItem, setIsSelectingItem] = useState(false);
  const shouldRenderDivider = index > 0;
  const ruleType = getValues(FormFieldRule.Type);
  const itemTypesOptions = Object.values(ItemType);
  const filteredItemTypesOptions: ItemOption[] = itemTypesOptions.reduce(function (
    availableItemType,
    itemType
  ) {
    let itemOption: ItemOption = {
      itemType: itemType,
      shouldRender: true,
    };

    const isRuleTypeSku = ruleType === RuleType.Sku;
    const isRuleTypeAdvertisement = ruleType === RuleType.Advertisement;
    const isOptionSku = itemType === ItemType.Sku;
    const isOptionPoster = itemType === ItemType.Poster;
    const isOptionCompetition = itemType === ItemType.Competition;

    if (
      (isOptionSku && !isRuleTypeSku) ||
      (isOptionPoster && !isRuleTypeAdvertisement) ||
      (isOptionCompetition && index == 0)
    ) {
      itemOption.shouldRender = false;
    }

    if (
      isOptionCompetition ||
      itemType == ItemType.Categories ||
      (isOptionPoster && isRuleTypeAdvertisement) ||
      (isOptionSku && isRuleTypeSku)
    ) {
      availableItemType.push(itemOption);
    }

    return availableItemType;
  },
  []);

  const itemTypesOptionsOrder = [
    ItemType.Sku,
    ItemType.Poster,
    ItemType.Categories,
    ItemType.Competition,
  ];
  const orderedItemTypesOptions = filteredItemTypesOptions.sort((a, b) => {
    const positionA = itemTypesOptionsOrder.indexOf(a.itemType);
    const positionB = itemTypesOptionsOrder.indexOf(b.itemType);
    return positionA - positionB;
  });

  const ruleOperationMethodError =
    (!!errorMethod &&
      formatMessage({ id: 'ruleCreation.form.formulaOperation.selectMethod.error' })) ||
    '';
  const ruleOperationOperatorError =
    (!!errorOperator &&
      formatMessage({ id: 'ruleCreation.form.formulaOperation.selectOperator.error' })) ||
    '';
  const ruleOperationConditionError =
    (!!errorCondition &&
      formatMessage({ id: 'ruleCreation.form.formulaOperation.selectCondition.error' })) ||
    '';
  const ruleOperationItemsError =
    ((!!errorItems || !!errorCategories) &&
      formatMessage({ id: 'ruleCreation.form.formulaOperation.selectItem.error' })) ||
    '';

  function handleSelectMethod(selectedMethod: FormulaMethod) {
    setValue(formPathMethod, selectedMethod);
    clearErrors([formPathMethod, formPathValue]);
    setValue(formPathValue, null);
    setIsPercentage(selectedMethod === FormulaMethod.OperationShare);
  }

  function handleSelectItem(selectedItem: ItemType) {
    setValue(formPathItemType, selectedItem);
    !!onSelectItem && onSelectItem({ operationIndex: index, selectedItem });
    if (selectedItem !== ItemType.Competition) {
      setIsSelectingItem(true);
    }
  }

  function handleSelectOperator(selectedOperator: FormulaOperator) {
    setValue(formPathOperator, selectedOperator);
    clearErrors(formPathOperator);
  }

  function handleSelectCondition(selectedCondition: FormulaCondition) {
    setValue(formPathCondition, selectedCondition);
    clearErrors(formPathCondition);
  }

  function handleDeleteItem() {
    onDeleteOperation(index);
  }

  function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const updatedValue = value.substring(0, 21);
    setValue(formPathValue, updatedValue);
    trigger(formPathValue);
  }

  useEffect(() => {
    if (isDrawerClosed) setIsSelectingItem(null);
  }, [isDrawerClosed]);

  function renderOperationValueError() {
    if (!!errorValue) {
      return errorValue.type === 'max'
        ? formatMessage({
            id: 'ruleCreation.form.formulaOperation.value.errorMaxShareAllowed',
          })
        : formatMessage({
            id: 'ruleCreation.form.formulaOperation.value.error',
          });
    } else {
      return '';
    }
  }

  function renderSelectItemsField() {
    if (isSelectingItem) {
      return (
        <Input
          data-testid="input-item"
          disabled
          label={formatMessage({
            id: 'ruleCreation.form.formulaOperation.selectItem.label',
          })}
          placeholder={formatMessage({
            id: 'ruleCreation.form.formulaOperation.selectItem.selectingItem',
          })}
          optionalText=""
          prefix={(<IconPlus size="medium" />) as unknown as string}
        />
      );
    } else {
      const ruleOperations: RuleOperation[] = getValues(FormFieldRule.Operations);
      const itemsLength = ruleOperations[index]?.count;
      const itemType = ruleOperations[index]?.itemType;
      const hasItems = itemsLength > 0;
      const messageIdBasedOnItemsCount = itemsLength > 1 ? 'plural' : 'singular';

      let selectPlaceholder;
      if (itemType === ItemType.Competition) {
        selectPlaceholder = formatMessage(
          {
            id: `ruleCreation.form.formulaOperation.selectItem.options.${itemType}`,
          },
          {
            companyName: userInfo.vendorName,
            ruleType: ruleType === RuleType.Advertisement ? 'Poster' : 'SKU',
          }
        );
      } else {
        selectPlaceholder = hasItems
          ? formatMessage(
              {
                id: `ruleCreation.form.formulaOperation.selectItem.selected.${messageIdBasedOnItemsCount}`,
              },
              {
                quantity: itemsLength,
              }
            )
          : formatMessage({
              id: 'ruleCreation.form.formulaOperation.selectItem.placeholder',
            });
      }

      return (
        <Select.Root
          data-testid="select-items"
          size="large"
          disabled={disabled}
          label={formatMessage({
            id: 'ruleCreation.form.formulaOperation.selectItem.label',
          })}
          placeholder={selectPlaceholder}
          icon={hasItems ? <List size="medium" /> : <Plus size="medium" />}
          onChange={handleSelectItem}
          error={ruleOperationItemsError}
        >
          {orderedItemTypesOptions.map((itemOption) => {
            return (
              <Select.Option
                key={itemOption.itemType}
                value={itemOption.itemType}
                data-testid="item-type-option"
                disabled={!itemOption.shouldRender}
              >
                <WrapperItemPlaceholder>
                  <Select.Label>
                    {formatMessage(
                      {
                        id: `ruleCreation.form.formulaOperation.selectItem.options.${itemOption.itemType}`,
                      },
                      {
                        companyName: userInfo.vendorName,
                        ruleType: ruleType === RuleType.Advertisement ? 'Poster' : 'SKU',
                      }
                    )}
                  </Select.Label>
                  <Paragraph size="small" colortype="disabled">
                    {formatMessage(
                      {
                        id: `ruleCreation.form.formulaOperation.selectItem.text.${itemOption.itemType}`,
                      },
                      {
                        ruleType: ruleType === RuleType.Advertisement ? 'Poster' : 'SKU',
                      }
                    )}
                  </Paragraph>
                </WrapperItemPlaceholder>
              </Select.Option>
            );
          })}
        </Select.Root>
      );
    }
  }

  const operatorIconMap = {
    [FormulaOperator.EqualsTo]: EqualIcon,
    [FormulaOperator.GreaterOrEqualsTo]: GreaterEqualIcon,
    [FormulaOperator.LessThan]: LesserIcon,
    [FormulaOperator.LessOrEqualsTo]: LesserEqualIcon,
    [FormulaOperator.GreaterThan]: GreaterIcon,
  };

  return (
    <>
      {shouldRenderDivider && (
        <SelectConditionContainer>
          <Divider />
          <FormulaOperatorCondition>
            <Select.Root
              data-testid="select-condition"
              shape="pill"
              size="small"
              width="100%"
              placeholder={formatMessage({
                id: 'ruleCreation.form.formulaOperation.selectCondition.placeholder',
              })}
              onChange={handleSelectCondition}
              value={getValues(formPathCondition)}
              error={ruleOperationConditionError}
              disabled={disabled}
            >
              {Object.values(FormulaCondition).map((formulaCondition) => {
                return (
                  <Select.Option key={formulaCondition} value={formulaCondition} disabled={false}>
                    {formatMessage({
                      id: `ruleCreation.form.formulaOperation.selectCondition.value.${formulaCondition}`,
                    })}
                  </Select.Option>
                );
              })}
            </Select.Root>
          </FormulaOperatorCondition>
          <Divider />
        </SelectConditionContainer>
      )}
      <FormulaOperationContainer>
        <FormulaOperatorField>
          <Select.Root
            data-testid="select-method"
            size="large"
            label={formatMessage({
              id: 'ruleCreation.form.formulaOperation.selectMethod.label',
            })}
            placeholder={formatMessage({
              id: 'ruleCreation.form.formulaOperation.selectMethod.placeholder',
            })}
            value={getValues(formPathMethod)}
            onChange={handleSelectMethod}
            error={ruleOperationMethodError}
            disabled={disabled}
          >
            {Object.values(FormulaMethod).map((formulaMethod) => {
              return (
                <Select.Option key={formulaMethod} value={formulaMethod} disabled={false}>
                  {formatMessage({
                    id: `ruleCreation.form.formulaOperation.selectMethod.value.${formulaMethod}`,
                  })}
                </Select.Option>
              );
            })}
          </Select.Root>
        </FormulaOperatorField>

        <FormulaOperatorField>{renderSelectItemsField()}</FormulaOperatorField>

        <FormulaOperatorField>
          <Select.Root
            data-testid="select-operator"
            size="large"
            disabled={disabled}
            label={formatMessage({
              id: 'ruleCreation.form.formulaOperation.selectOperator.label',
            })}
            placeholder={formatMessage({
              id: 'ruleCreation.form.formulaOperation.selectOperator.placeholder',
            })}
            onChange={handleSelectOperator}
            error={ruleOperationOperatorError}
            value={getValues(formPathOperator)}
          >
            {Object.values(FormulaOperator).map((formulaOperator) => {
              const operatorIcon = operatorIconMap[formulaOperator];
              return (
                <Select.Option key={formulaOperator} value={formulaOperator} disabled={false}>
                  <WrapperSelectOperatorValues>
                    <img src={operatorIcon} alt={formulaOperator} />
                    <Select.Label>
                      {formatMessage({
                        id: `ruleCreation.form.formulaOperation.selectOperator.value.${formulaOperator}`,
                      })}
                    </Select.Label>
                  </WrapperSelectOperatorValues>
                </Select.Option>
              );
            })}
          </Select.Root>
        </FormulaOperatorField>

        <WrapperFormulaOperatorFieldValue>
          <FormulaOperatorField>
            <InputLabel className={disabled ? 'disabled' : ''} hasError={!!errorValue}>
              {formatMessage({ id: 'ruleCreation.form.formulaOperation.value.label' })}
            </InputLabel>
            <InputValueContainer>
              <InputValue
                {...register(formPathValue)}
                width={'100%'}
                data-testid="input-value"
                disabled={disabled}
                placeholder={formatMessage({
                  id: 'ruleCreation.form.formulaOperation.value.placeholder',
                })}
                optionalText=""
                value={getValues(formPathValue)}
                type="number"
                hasError={!!errorValue}
                errorText={renderOperationValueError()}
                onChange={handleChangeValue}
                onWheel={(event: any) => {
                  event?.target?.blur();
                  event.stopPropagation();
                }}
                min={0}
              />
              <InputValuePercentageSymbol isPercentage={isPercentage} hasError={!!errorValue}>
                %
              </InputValuePercentageSymbol>
            </InputValueContainer>
          </FormulaOperatorField>
          <ActionsButtonsWrapper>
            <IconButton
              type="button"
              icon={Trash2}
              variant="tertiary"
              size="small"
              onClick={handleDeleteItem}
              data-testid={`delete-operation-${index}`}
            />
          </ActionsButtonsWrapper>
        </WrapperFormulaOperatorFieldValue>
      </FormulaOperationContainer>
    </>
  );
};
